.qr-modal-body {
  display: flex;
  justify-content: space-between;
}

.qr-modal-head h2 {
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  color: #ffffff;
  margin: 0;
}
.cpn-qr-modal {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.qr-modal-title h5 {
  font-weight: 410;
  font-size: 20px;
  line-height: 26px;
  color: #fff;
  margin: 0;
}

.qr-modal-left {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 630px;
  width: 100%;
}

.qr-modal-body ol {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0;
}

.qr-modal-left ol li {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #fff;
}

.qr-list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.qr-list-logos {
  display: flex;
  gap: 8px;
}

.qr-modal-right {
  padding-left: 50px;
  border-left: 2px solid #454851;
}

.close-model-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.qr-wrapper {
  padding: 50px;
  background: #fff;
  border-radius: 8px;
}

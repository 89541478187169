.multi-table-game-view {
  position: relative;
  height: 324px;
  width: 520px;
  overflow: hidden;
  background: #31343e;
  border: 1px solid #373940;
  border-radius: 16px;
}

.table-hover-switch {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  background: rgba(35, 38, 46, 0.7);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 9;
  cursor: pointer;
}

.table-hover-switch p {
  max-width: 129px;
  width: 100%;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.multi-table-game-view:hover .table-hover-switch {
  opacity: 1;
  visibility: visible;
}

.multi-table-slider-main {
  width: 1600px;
}

.multi-table-slider-main .slick-arrow::before {
  display: none;
}

.multi-table-slider-main .slick-arrow::after {
  position: absolute;
  background: unset;
  content: "";
  font-family: unset;
  font-size: unset;
  line-height: unset;
  background-image: url("../../assets/images/Left.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
}

.multi-table-slider-main .slick-prev,
.multi-table-slider-main .slick-next {
  width: 32px;
  height: 32px;
}

.multi-table-slider-main .slick-prev {
  left: -32px;
}
.multi-table-slider-main .slick-next {
  right: -24px;
  transform: rotate(180deg) translateY(50%);
}

.multi-table-slider-main .slick-disabled::after {
  opacity: 0.5;
}

/*********************************** Multi table grid view ************************************/

.multi-table-game-view .logo {
  display: none;
}

.multi-table-game-view .player-game-sidebar {
  display: none;
}

.multi-table-game-view .player-game-grid {
  width: 520px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  gap: 4px;
  padding: 12px;
  background: #31343e;
  border: 1px solid #373940;
  border-radius: 16px;
}

.multi-table-game-view .player-game-view-grid {
  max-width: 150px;
}

.multi-table-game-view .player-length-1 .player-box {
  height: 296px;
  border-width: 2px;
}

.multi-table-game-view .player-length-1 .player-box-view {
  height: 233px;
}

.multi-table-game-view .player-dropdown {
  display: none;
}

.multi-table-game-view .company-logo-grid {
  display: none;
}

.multi-table-game-view .player-box-user span {
  display: none;
}

.multi-table-game-view .player-box-user {
  justify-content: center;
  padding: 2px;
}

.multi-table-game-view .player-length-1 .player-box-user h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.multi-table-game-view .player-length-1 .player-box-action-grid,
.multi-table-game-view .player-length-2 .player-box-action-grid {
  padding: 4px;
  width: 146px;
  height: 54px;
  border: none;
}

.multi-table-game-view .player-length-1 .player-action-view-check,
.multi-table-game-view .player-length-1 .player-action-view-fold,
.multi-table-game-view .player-length-1 .player-action-view-show {
  height: 46px;
}

.multi-table-game-view .player-action-view-fold span {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.multi-table-game-view .player-game-dealing {
  max-width: 180px;
}

.multi-table-game-view .player-camera.view-1 .player-camera-box img {
  height: 145px !important;
}

.multi-table-game-view .dealer-name-wrapper {
  display: none;
}

.multi-table-game-view .player-camera-box {
  border-width: 2px;
}

.multi-table-game-view .tournament-game-view .automated-community-card-wrapper {
  border: none;
  min-height: 50.75px;
  padding: 0;
  gap: 2px;
  border-radius: 1.58585px;
}

.multi-table-game-view .player-bid-amount {
  flex-direction: column;
  padding-bottom: 0;
}

.multi-table-game-view .tournament-game-view .player-bid-box {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.multi-table-game-view .player-bid-icon {
  display: none;
}

.multi-table-game-view .player-bid {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  min-height: unset;
}

.multi-table-game-view .tournament-game-view .player-bid-info h4 {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}

.multi-table-game-view .player-game-action {
  display: none;
}

.multi-table-game-view .player-pot-count ul li {
  width: 100%;
}

.multi-table-game-view
  .tournament-game-view
  .automated-community-card-wrapper
  span
  img {
  width: 100%;
  height: 50.75px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 1.58585px;
}

.multi-table-game-view
  .player-game-right-view.player-length-1
  .player-box-card {
  transform: scale(42%) translate(22%, 0%);
}

.multi-table-game-view .layer-game-not-started-content.pause-animation,
.multi-table-game-view .layer-game-not-started-content {
  transform: scale(50%) translate(-50px, 50px);
}

.multi-table-game-view .player-length-1 .player-action-empty-field {
  min-height: 46px;
  width: 74px;
}

.multi-table-game-view .player-length-1 .player-box-card,
.multi-table-game-view .player-length-2 .player-box-card {
  transform: scale(42%) translate(-68%, 0%);
}

.multi-table-game-view
  .player-game-left-view.player-length-1
  .player-box-action {
  margin-left: -74px;
}

.multi-table-game-view .player-length-1 .player-action-view,
.multi-table-game-view .player-length-2 .player-action-view {
  padding: 4px;
  height: 46px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.multi-table-game-view .player-length-1 .player-action-view-text,
.multi-table-game-view .player-length-2 .player-action-view-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.multi-table-game-view .player-length-1 .player-action-view h4,
.multi-table-game-view .player-length-1 .player-action-view-bet span,
.multi-table-game-view .player-length-2 .player-action-view h4,
.multi-table-game-view .player-length-2 .player-action-view-bet span {
  font-size: 14px;
  line-height: 18px;
}

.multi-table-game-view .player-box-iframe.multi-table-avatar {
  display: block;
}

.multi-table-game-view .player-box-iframe {
  display: none;
}

.multi-table-game-view .main-player-camoff {
  flex-direction: column;
  gap: 10px;
}

.multi-table-game-view .main-player-camoff img {
  border: 3px solid #373940;
  border-radius: 100px;
  width: 119px;
  height: 119px;
}

.multi-table-game-view .main-player-camoff span {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
  text-align: center;
}

.multi-table-game-view .player-action-winner span {
  font-size: 12px;
  line-height: 14px;
}

.multi-table-game-view .player-action-winner p {
  font-size: 9px;
  line-height: 11px;
}

.multi-table-game-view .player-action-winner {
  padding: 4px;
  width: 74px;
  height: 46px;
}

.multi-table-game-view .winner-player .player-box-action-grid {
  border: none;
}

.multi-table-game-view .player-length-2 .player-box-view {
  height: 90px;
}

.multi-table-game-view .player-length-2 .player-box {
  height: 146px;
}

.multi-table-game-view .player-box {
  border-width: 2px;
  border-radius: 2px;
}

.multi-table-game-view .player-length-2 .player-box-user h4 {
  font-size: 16px;
  line-height: 20px;
}

.multi-table-game-view .player-length-2 .main-player-camoff img {
  width: 40px;
  height: 40px;
  border-width: 2px;
}

.multi-table-game-view .player-length-2 .main-player-camoff {
  justify-content: flex-start;
  padding-top: 6px;
  gap: 4px;
}

.multi-table-game-view
  .player-game-right-view.player-length-2
  .player-box-card {
  transform: scale(42%) translate(68%, 0%);
}

.multi-table-game-view
  .player-game-right-view.player-length-2
  .player-box-action {
  margin-right: -68px;
}
.multi-table-game-view .player-length-2 .player-box-action {
  width: calc(100% - 60px);
}

.multi-table-game-view .player-length-2 .player-action-view-check,
.multi-table-game-view .player-length-2 .player-action-view-fold,
.multi-table-game-view .player-length-2 .player-action-view-show,
.multi-table-game-view .player-length-2 .player-action-empty-field,
.multi-table-game-view .player-length-2 .player-action-view,
.multi-table-game-view .player-length-2 .player-action-winner {
  height: 38px;
  padding: 4px;
  gap: 0px;
  min-height: unset;
}

.multi-table-game-view
  .player-game-left-view.player-length-2
  .player-box-action {
  margin-left: -70px;
}

.multi-table-game-view .dealer-name-wrapper.multi-table-count {
  display: block;
}

.multi-table-game-view .layout-header-grid {
  display: none;
}

.multi-table-game-view .player-length-3 .main-player-camoff img,
.multi-table-game-view .player-length-4 .main-player-camoff img,
.multi-table-game-view .player-action-view-bet span img {
  display: none;
}

.multi-table-game-view .player-length-3 .player-box {
  height: 96px;
  width: 150px;
}

.multi-table-game-view .player-length-3 .player-box-view,
.multi-table-game-view .player-length-4 .player-box-view {
  width: 78px;
}

.multi-table-game-view
  .player-length-3
  .player-box-action-grid.multi-table-game-view,
.multi-table-game-view
  .player-length-4
  .player-box-action-grid.multi-table-game-view {
  border: none;
}

.multi-table-game-view .player-length-3 .main-player-camoff {
  justify-content: flex-start;
  padding-top: 18px;
}

.multi-table-game-view .player-length-3 .player-box-user,
.multi-table-game-view .player-length-4 .player-box-user {
  padding: 2px;
  gap: 4.05px;
  height: 40px;
}

.multi-table-game-view .player-length-3 .player-box-user h4,
.multi-table-game-view .player-length-4 .player-box-user h4 {
  font-size: 16px;
  line-height: 20px;
}

.multi-table-game-view .player-length-3 .player-box-action {
  max-width: unset;
}

.multi-table-game-view .player-length-3 .player-box-action-grid,
.multi-table-game-view .player-length-4 .player-box-action-grid {
  width: 70px;
  border: 0;
  flex-wrap: nowrap;
}

.multi-table-game-view .player-length-3 .player-action-view,
.multi-table-game-view .player-length-3 .player-action-view-fold,
.multi-table-game-view .player-length-3 .player-action-view-show,
.multi-table-game-view .player-length-4 .player-action-view,
.multi-table-game-view .player-length-4 .player-action-view-fold,
.multi-table-game-view .player-length-4 .player-action-view-show {
  min-height: unset;
  height: 40px;
  font-size: 14px;
  line-height: 18px;
}

.multi-table-game-view .player-length-3 .player-action-view-text,
.multi-table-game-view .player-length-4 .player-action-view-text {
  font-size: 8px;
  line-height: 14px;
  font-weight: 400;
}

.multi-table-game-view .player-length-3 .player-action-view h4,
.multi-table-game-view .player-length-4 .player-action-view h4 {
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.multi-table-game-view .player-length-3 .player-action-view span,
.multi-table-game-view .player-length-4 .player-action-view span {
  display: none;
}

.multi-table-game-view .player-length-3 .player-action-empty-field,
.multi-table-game-view .player-length-3 .player-action-view-check,
.multi-table-game-view .player-length-4 .player-action-view-check,
.multi-table-game-view .player-length-4 .player-action-empty-field {
  min-height: 40px;
  height: 40px;
}

.multi-table-game-view .player-length-3 .player-box-card {
  transform: scale(45%) translate(-66%, 62%);
}

.multi-table-game-view .player-length-3 .player-box-grid,
.multi-table-game-view .player-length-4 .player-box-grid {
  flex-wrap: nowrap;
}

.multi-table-game-view .player-length-3 .player-box-user:before,
.multi-table-game-view .player-length-4 .player-box-user:before {
  background: #373940;
  -webkit-backdrop-filter: blur(1.2143px);
  backdrop-filter: blur(1.2143px);
}

.multi-table-game-view .player-length-3 .player-action-winner,
.multi-table-game-view .player-length-4 .player-action-winner {
  width: unset;
  height: 40px;
}

.multi-table-game-view .add-time {
  display: none;
}

.multi-table-game-view .player-length-4 .player-box {
  width: 150px;
  height: 71px;
}

.multi-table-game-view .player-length-4 .main-player-camoff {
  justify-content: flex-start;
  padding-top: 6px;
}

.multi-table-game-view .player-length-4 .player-box-action {
  max-width: unset;
  position: absolute;
  width: 65px;
  height: 40px;
  background: #373940;
}

.multi-table-game-view .player-length-4 .player-action-winner {
  width: 65px;
  height: 40px;
  min-height: 40px;
}

.multi-table-game-view .player-length-4 .player-box-card {
  transform: scale(45%) translate(-66%, 10%);
}

.multi-table-game-view
  .player-camera.view-1.dealer-camera-view
  .player-camera-box
  video,
.multi-table-game-view .player-camera.view-1 .player-camera-box video {
  height: 200px !important;
}

.multi-table-game-view .multi-table-dealer-view {
  display: block;
}

.multi-table-game-view .dealer-cam--view {
  display: none;
}

.multi-table-game-view .player-pot-count ul {
  flex-wrap: nowrap;
}

.multi-table-game-view .player-pot-count ul li span {
  font-size: 8px;
  line-height: normal;
}

.multi-table-game-view .player-pot-count ul li h4 {
  font-size: 14px;
  line-height: normal;
}

.multi-table-game-view .player-pot-count ul li:nth-child(3) {
  border: none;
}
.multi-table-game-view .player-pot-count ul li:nth-child(4),
.multi-table-game-view .player-pot-count ul li:nth-child(5),
.multi-table-game-view .player-pot-count ul li:nth-child(6),
.multi-table-game-view .player-pot-count ul li:nth-child(7),
.multi-table-game-view .player-pot-count ul li:nth-child(8),
.multi-table-game-view .player-pot-count ul li:nth-child(9) {
  display: none;
}

/*********************************** Multi table grid view ************************************/

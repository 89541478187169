.signup {
  background: #23262e;
}

.signup-content {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-form {
  background: #31343e;
  box-shadow: 10px 10px 60px #1b1d23;
  border-radius: 20px;
  max-width: 422px;
  width: 100%;
  margin: 0px auto;
  padding: 32px 50px;
}

.signup-form h3 {
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #83bc9a;
  margin: 0;
  padding-bottom: 24px;
}

.signup-form h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #a0aabb;
  margin: 0;
}

.lets-play-btn,
.lets-play-btn:hover {
  background: #c7ad7a;
  border-radius: 6px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-decoration: none;
  height: 50px;
  font-family: "DDCHardware" !important;
  cursor: pointer;
}

.signup-form-icon {
  text-align: center;
  padding-bottom: 10px;
}

.signup-form-icon img {
  max-width: 68px;
  width: 100%;
  margin: 0px auto;
  display: block;
}

.signup-form .form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0aabb;
  margin-bottom: 9px;
}

.signup-form .form-control,
.signup-form .form-control:focus {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  padding: 5px 12px;
}

.signup-form .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.signup-form .form-control::-moz-placeholder {
  color: #ffffff;
}

.signup-form .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.signup-form .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.signup-form .form-control::placeholder {
  color: #ffffff;
}

.signup-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.half-width {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.half-width .form-group {
  width: 47%;
}

.signup-btn .btn {
  background: #c7ad7a;
  border-radius: 6px;
  border-color: #c7ad7a;
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  font-family: "DDCHardware" !important;

  transition: all 0.5s;
}
.signup-btn .btn:hover,
.signup-btn .btn:focus,
.signup-btn .btn-check:checked + .signup-btn .btn,
.signup-btn .btn.active,
.signup-btn .btn.show,
.signup-btn .btn:first-child:active,
:not(.signup-btn .btn-check) + .signup-btn .btn:active,
.signup-btn .btn:focus-visible {
  background: #af935d;
  border-color: #af935d;
  outline: 0;
}
.signup-btn .btn:focus,
.signup-btn .btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.signup-btn {
  padding-top: 12px;
  padding-bottom: 12px;
}

.signup-form p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding-top: 12px;
}

.signup-form p a {
  color: #c7ad7a;
  text-decoration: none;
}

.signup-form .form-group span {
  position: absolute;
  top: 36px;
  right: 12px;
  cursor: pointer;
}

.error-field {
  border: 1px solid #d93355 !important;
  box-shadow: none !important;
}

.error-text {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #d93355 !important;
  padding-top: 8px;
}

.signup .react-tel-input .form-control,
.signup .react-tel-input .form-control:focus {
  width: 100%;
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  padding: 18.5px 14px 18.5px 60px;
}

.signup .react-tel-input .selected-flag .arrow {
  border-top: 4px solid #fff;
}

.signup .react-tel-input .selected-flag .arrow.up {
  border-bottom: 4px solid #fff;
}

.signup .react-tel-input span {
  color: #6b6b6b;
  position: relative;
  top: auto;
  right: auto;
}

.signup .react-tel-input .selected-flag:focus:before,
.signup .react-tel-input .selected-flag.open:before {
  display: none;
}
.game-cp-wrapper h4 {
  margin: 0;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  color: #fff;
  padding-bottom: 2px;
}

/************************* Success ************************/

.success-toast {
  background: #83bc9a;
  box-shadow: 10px 10px 60px #1b1d23;
  border-radius: 12px;
  position: fixed;
  right: 0px;
  top: 20px;
  max-width: 420px;
  width: 100%;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.2s;
  animation: slide 0.5s forwards;
  animation-delay: 0.2s;
  visibility: hidden;
  opacity: 0;
  z-index: 9999;
}

.success-toast-grid {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 60px;
}

.success-toast-icon {
  width: 24px;
}

.success-toast-icon img {
  width: 100%;
}

.success-toast-content {
  width: calc(100% - 34px);
}

.success-toast-content p {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.success-toast-close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/************************* Error ************************/

.error-toast {
  background: #d93355;
  box-shadow: 10px 10px 60px #1b1d23;
  border-radius: 12px;
  position: fixed;
  right: 0px;
  top: 20px;
  max-width: 420px;
  width: 100%;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.2s;
  animation: slide 0.5s forwards;
  animation-delay: 0.2s;
  visibility: hidden;
  opacity: 0;
  z-index: 9999;
}

.error-toast-grid {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 60px;
}

.error-toast-icon {
  width: 24px;
}

.error-toast-icon img {
  width: 100%;
}

.error-toast-content {
  width: calc(100% - 34px);
}

.error-toast-content p {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.error-toast-close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/************************* Invite ************************/

.invite-toast {
  background: #4c505e;
  box-shadow: 10px 10px 60px #1b1d23;
  border-radius: 12px;
  position: fixed;
  right: 0px;
  top: 20px;
  max-width: 420px;
  width: 100%;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.2s;
  animation: slide 0.5s forwards;
  animation-delay: 0.2s;
  visibility: hidden;
  opacity: 0;
  z-index: 9999;
}

.invite-toast-grid {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invite-toast-content {
  width: calc(100% - 110px);
}

.invite-toast-content p {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.invite-toast-action {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invite-toast-action span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  cursor: pointer;
}

.invite-toast-action span img {
  display: block;
  margin: 0px auto;
}

.invite-toast-action .invite-accept {
  background: #83bc9a;
}

.invite-toast-action .invite-reject {
  background: #d93355;
}

/************************* No Internet ************************/

.internet-toast {
  background: #d93355;
  box-shadow: 10px 10px 60px #1b1d23;
  border-radius: 12px;
  position: fixed;
  right: 0px;
  top: 20px;
  max-width: 420px;
  width: 100%;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.2s;
  animation: slide 0.5s forwards;
  animation-delay: 0.2s;
  visibility: hidden;
  opacity: 0;
  z-index: 9999;
}

.internet-toast-grid {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 60px;
}

.internet-toast-icon {
  width: 24px;
}

.internet-toast-icon img {
  width: 100%;
}

.internet-toast-content {
  width: calc(100% - 34px);
}

.internet-toast-content h4 {
  padding-bottom: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  font-family: "Gilroy" !important;
}
.internet-toast-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 0;
}
.internet-toast-close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/************************** Notification *************************/

.notification-toast {
  background: #454851;
  box-shadow: 0px 10px 60px #1b1d23;
  border-radius: 12px;
  max-width: 300px;
  width: 100%;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.2s;
  animation: slide 0.5s forwards;
  animation-delay: 0.2s;
  visibility: hidden;
  opacity: 0;
}

.notification-toast-grid {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-toast-grid p {
  font-family: "Gilroy";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #a5a5a5;
  margin: 0;
}

.notification-toast-grid p span {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  padding-right: 5px;
}

.notification-toast:nth-child(2) .notification-toast-grid {
  opacity: 0.5;
}

.notification-toast:nth-child(3) .notification-toast-grid {
  opacity: 0.3;
}

.notification-toast:nth-child(2) {
  background: rgb(69 72 81 / 50%);
}

.notification-toast:nth-child(3) {
  background: rgb(69 72 81 / 30%);
}

.one-to-one-info-toast .invite-toast-action {
  width: unset;
}

.one-to-one-info-toast .invite-toast-content p {
  font-size: 14px;
}

.one-to-one-info-toast .invite-toast-content {
  width: calc(100% - 40px);
}

.one-to-one-info-toast .invite-reject {
  background: transparent;
  width: unset;
  height: unset;
}

.one-to-one-call-toast .invite-toast-action p {
  margin: 0;
  color: #fff;
}

/************************* Slide ANimation ***********************/

@-webkit-keyframes slide {
  100% {
    right: 20px;
    visibility: visible;
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    right: 20px;
    visibility: visible;
    opacity: 1;
  }
}

.modal-90w {
  max-width: 1328px !important;
  width: 100% !important;
}
.game-detail-modal .modal-content {
  border-radius: 20px;
  border: none;
  background: #31343e;
}

.game-detail-modal .modal-body {
  padding: 0;
}

.game-details {
  padding: 50px;
}

.game-details-head {
  display: flex;
  justify-content: space-between;
}

.game-details-head h2 {
  font-family: "DDCHardware" !important;
  font-style: normal;
  font-weight: 410;
  font-size: 36px;
  line-height: 46px;
  color: #ffffff;
  margin: 0;
}

.details-action-btn {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 385px;
  width: 100%;
}

.details-action-btn .delete-btn {
  background: #d93355;
  border-radius: 6px;
  max-width: 179px;
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  border: none;
  font-family: "DDCHardware" !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  transition: all 0.5s;
}

.details-action-btn .delete-btn:hover,
.details-action-btn.delete-btn:focus,
.details-action-btn :not(.btn-check) + .btn:active,
.details-action-btn .delete-btn:focus-visible,
.details-action-btn .delete-btn.btn:first-child:active {
  background: #d6002b;
  border: none;
  outline: 0;
}

.details-action-btn .delete-btn:focus,
.details-action-btn .delete-btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.details-action-btn .delete-btn:disabled {
  background: #454851;
  color: #31343e;
  border: none;
}

.details-action-btn .save-btn {
  background: #c7ad7a;
  border-radius: 10px;
  max-width: 186px;
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  border-color: #c7ad7a;
  font-family: "DDCHardware" !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  transition: all 0.5s;
}

.details-action-btn .save-btn:hover,
.details-action-btn.save-btn:focus,
.details-action-btn :not(.btn-check) + .btn:active,
.details-action-btn.save-btn:focus-visible {
  background: #af935d;
  border-color: #af935d;
  outline: 0;
}

.details-action-btn .save-btn:focus,
.details-action-btn.save-btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.details-action-btn .save-btn:disabled {
  background: #454851;
  color: #31343e;
  border: none;
}

.detail-form-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 36px;
}

.detail-form-section {
  max-width: calc(100% - 465px);
  width: 100%;
  border-right: 2px solid #454851;
  padding-right: 50px;
}

.detail-form-section .bind-structure h6 {
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  text-align: center;
}

.detail-form-section .bind-structure button.slick-arrow.slick-prev::before {
  content: "" !important;
  background: url("../../../assets/images/dealer/leftarrow.svg");
  height: 26px;
  width: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 12px;
}

.detail-form-section .bind-structure button.slick-arrow.slick-next::before {
  content: "" !important;
  background: url("../../../assets/images/dealer/rightarrow.svg");
  height: 26px;
  width: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
}

.detail-form-section .bind-structure .bind-list {
  border-left: 1px solid #4c505e;
}

.detail-form-section .bind-structure .slick-list {
  width: 80%;
  margin: 0px auto;
}

.detail-form-section .bind-structure .slick-prev {
  left: -2px;
}

.detail-form-section .bind-structure .slick-next {
  right: 12px;
}

.detail-form-section .bind-structure {
  padding: 8px 0px;
  width: 342px;
  height: 56px;
  background: #454851;
  border-radius: 6px;
}

.detail-form-section .bind-structure p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a0aabb;
  margin: 0;
  text-align: center;
}
.detail-form-section .half-width.time-box {
  width: 48%;
}
.detail-form-section .half-width.time-box .time-zone-box {
  width: 48%;
}
.detail-form-section .half-width.time-box .time-zone-box .form-group {
  width: 100%;
}
.detail-form-section
  .game-filter-date
  .react-datepicker__input-container
  input {
  height: 40px;
}

.detail-form-links {
  max-width: 465px;
  width: 100%;
  padding-left: 50px;
}

.detail-form-grid .form-group {
  padding-bottom: 20px;
}

.detail-form-grid .form-control,
.detail-form-grid .form-control:focus {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  padding: 5px 12px;
  box-shadow: none;
}

.detail-form-grid .form-label {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a0aabb;
  padding-bottom: 10px;
  margin: 0;
}

.two-input-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 28px;
}

.two-input-grid .form-group {
  width: 48%;
}

.blind-info {
  width: 48%;
}

.blind-info .two-input-grid {
  gap: 8px;
}

.game-list-section {
  width: 100%;
  padding-top: 12px;
}

.game-list-table {
  border: 1px solid #454851;
  border-radius: 8px;
}

.game-list-tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
}

.game-list-th .game-list-tr {
  background: #454851;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.game-list-body .game-list-tr {
  border-top: 1px solid #454851;
}

.game-list-tr h6 {
  font-family: "DDCHardware" !important;
  font-style: normal;
  font-weight: 410;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.game-list-sr {
  max-width: 100px;
  width: 100%;
  text-align: center;
  padding: 16px;
}

.game-list-link,
.game-list-player,
.game-intro-video {
  position: relative;
  max-width: 260px;
  width: 100%;
  padding: 16px;
}

.game-intro-video span.video-upload-error {
	position: absolute;
    font-size: 12px;
    color: #d93355;
    font-weight: 500;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
}

.game-list-delete {
  max-width: 86px;
  width: 100%;
  padding: 16px;
}

.game-list-player p {
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-transform: capitalize;
  margin: 0;
}

.game-list-link button {
  background: transparent;
  border: none;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #c7ad7a;
  text-decoration: underline;
  display: flex;
  gap: 4px;
}

.game-list-link button:hover,
.game-list-link button:focus,
.game-list-link button :not(.btn-check) + .btn:active,
.game-list-link button:focus-visible,
.game-list-link .btn:first-child:active {
  background: #373940;
  border-radius: 4px;
  border: none;
  outline: 0;
  color: #c7ad7a;
}

.game-list-link button:focus,
.game-list-link button:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.game-list-link button:disabled {
  background: transparent;
  border: none;
  color: #373940;
}

.game-list-link span.copied-tooltip {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #909090;
  gap: 4px;
  padding: 6px 10px;
}

.game-intro-video .intro-drag-video {
  border: 1px solid #c7ad7a;
  border-radius: 6px;
  background: transparent;
  text-transform: uppercase;
  max-width: 234px;
  width: 100%;
  height: 34px;
  padding: 8px 20px;
}
.game-intro-video .intro-drag-video p {
  margin: 0;
  font-family: "DDCHardware" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #c7ad7a;
}
.game-intro-video .intro-drag-video:hover {
  border: 1px solid #af935d;
  color: #af935d;
}
.game-intro-video .intro-uploading-video {
  background: transparent;
  border: none;
  border-radius: 6px;
  padding: 2px 20px;
  margin-bottom: 0px;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
}
.game-intro-video .intro-uploading-video p {
  margin: 0;
}
.game-intro-video .intro-uploading-close {
  top: -3px;
}
.game-intro-video .intro-uploading-video-list {
  margin-bottom: 0;
}

.game-intro-video button:hover,
.game-intro-video button:focus,
.game-intro-video button :not(.btn-check) + .btn:active,
.game-intro-video button:focus-visible,
.game-intro-video .btn:first-child:active {
  background: transparent;
  border-color: #af935d;
  outline: 0;
  color: #af935d;
}

.game-intro-video button:focus,
.game-intro-video button:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.game-intro-video button:disabled {
  background: transparent;
  border: none;
  color: #373940;
}

.game-intro-video .uploaded-video-grid {
  background: #454851;
  border-radius: 6px;
  max-width: 248px;
  width: 100%;
  height: 34px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  color: #ffffff;
}
.uploaded-video-name {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 206px;
}
.game-intro-video .intro-uploading-list-delete {
  background: #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
}
.game-intro-video .intro-uploading-list-delete span {
  text-decoration: unset;
  color: #4c505e;
  line-height: 20px;
}

.game-intro-video.uploaded {
  padding: 14px 8px;
}

.game-list-delete button {
  background: transparent;
  border: none;
}

.game-list-delete button:hover,
.game-list-delete .btn:first-child:active {
  background: transparent;
  border: none;
}
.game-list-delete .btn:disabled {
  background: #454851;
  color: #373940;
}

.delete-popup-text h6 {
  font-family: "DDCHardware" !important;
  font-style: normal;
  font-weight: 410;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding-bottom: 16px;
}

.delete-popup-text p {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 20px;
}
.load-more {
  padding-top: 20px;
}
.load-more .btn {
  background: #c7ad7a;
  border-radius: 6px;
  max-width: 40px;
  width: 100%;
  height: 40px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  padding: 0;
  border: none;
}
.load-more .btn:hover,
.load-more .btn:focus,
.load-more .btn :not(.btn-check) + .btn:active,
.load-more .btn:focus-visible,
.load-more .btn:first-child:active {
  background: #af935d;
  border-color: #af935d;
  outline: 0;
}
.load-more .btn:focus,
.load-more .btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}
.load-more .btn:disabled {
  background: #454851;
  color: #373940;
}

@media screen and (max-width: 1024px) {
  .game-details {
    padding: 40px;
  }
  .modal-90w {
    max-width: 950px !important;
  }
  .detail-form-section {
    max-width: calc(100% - 300px);
    padding-right: 20px;
  }
  .detail-form-links {
    max-width: 300px;
    padding-left: 20px;
  }
  .two-input-grid {
    gap: 14px;
  }
  .detail-form-section .bind-structure {
    max-width: 342px;
    width: 100%;
  }
  .game-list-sr {
    padding: 16px 8px;
  }
  .game-list-link,
  .game-list-player,
  .game-intro-video {
    max-width: 175px;
    padding: 16px 8px;
  }
}

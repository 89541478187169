/************************* Success ************************/

.success-toaster {
  border: 1px dashed #83bc9a;
  margin: 10px auto;
}

.success-toaster-grid {
  padding: 10px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.success-toaster-icon {
  width: 24px;
}

.success-toaster-icon img {
  width: 100%;
}

.success-toaster-content {
  width: calc(100% - 40px);
}

.success-toaster-content p {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

/************************* Error ************************/

.error-toaster {
  border: 1px dashed #d93355;
  margin: 10px auto;
}

.error-toaster-grid {
  padding: 10px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error-toaster-icon {
  width: 24px;
}

.error-toaster-icon img {
  width: 100%;
}

.error-toaster-content {
  width: calc(100% - 40px);
}

.error-toaster-content p {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

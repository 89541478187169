.dealer {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.dealer-companion {
  max-width: 1302px;
  margin: 0px auto;
  position: relative;
  padding: 80px 0px 0px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-icon {
  position: absolute;
  left: 40px;
  top: 36px;
}

.logo-icon img {
  width: 40px;
}

/*************************** Poker Table *******************************/

.poker-table {
  background: url(../../../assets/images/dealer/table-bg.svg);
  width: 1302px;
  height: 829px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0px auto;
  display: block;
  position: relative;
}

.poker-table:before {
  content: "";
  background: #4c505c;
  width: 150px;
  height: 450px;
  display: block;
  position: absolute;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%);
  mix-blend-mode: darken;
}

/*************************** Players *******************************/

.player {
  max-width: 233px;
  position: absolute;
  width: 100%;
}

.player5 {
  bottom: 90px;
  left: 22.5%;
}

.player6 {
  bottom: 272px;
  left: -22px;
}

.player7 {
  top: 180px;
  left: -22px;
}

.player8 {
  top: -18px;
  left: 22.5%;
}

.player1 {
  top: -18px;
  right: 22.5%;
}

.player2 {
  top: 180px;
  right: -22px;
}

.player3 {
  bottom: 272px;
  right: -22px;
}

.player4 {
  bottom: 90px;
  right: 22.5%;
}

.player-grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  justify-content: flex-end;
}

.player-cover {
  width: 100px;
  position: absolute;
  z-index: 9;
  left: 0px;
}

.player-pic {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 3px solid #23262e;
  background: #373940;
  top: -5px;
  position: relative;
}

.player-pic img {
  border-radius: 100%;
  width: 94px;
  height: 94px;
}

.player-content {
  position: relative;
  width: calc(100% - 50px);
}

.player-card {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  top: -38px;
  width: 100%;
  justify-content: center;
  padding-left: 50px;
}

.player-card img {
  max-width: 56px;
  margin: 0px 1px;
  width: 100%;
}

.player-bet {
  position: relative;
  background: #373940;
  border-radius: 0px 12px 12px 0px;
}

.player-stack {
  background: #373940;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  top: -3px;
  left: 0;
  cursor: pointer;
  z-index: 9;
}

.player-bet h4 {
  font-weight: 400;
  font-size: 26px;
  color: #ffffff;
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #454851;
  padding: 5px 10px;
  line-height: 1;
  padding-left: 50px;
}

.player-bet span {
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;

  color: #a0aabb;
  text-align: center;
  display: block;
  padding: 7px 10px;
  width: 50%;
  padding-left: 45px;
}

.player-timer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.player-timer h6 {
  margin: 0;
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;
  color: #ffffff;
  border-left: 1px solid #454851;
  padding: 0px 10px;
  width: 50%;
}

.player-name {
  position: relative;
  text-align: center;
  top: -18px;
  display: table;
  margin: 0px auto;
}

.player-name:before {
  content: "";
  background: rgba(35, 38, 46, 0.8);
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.player-name p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  position: relative;
  padding: 5px 8px;
  cursor: pointer;
}

.dealer-symbol {
  background: #454851;
  box-shadow: 10px 10px 60px #1b1d23;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
  font-family: "DDCHardware" !important;
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}
.player1 .dealer-symbol,
.player2 .dealer-symbol,
.player3 .dealer-symbol,
.player4 .dealer-symbol {
  left: -16px;
}

.player1 .player-cover,
.player2 .player-cover,
.player3 .player-cover,
.player4 .player-cover {
  right: 0px;
  left: auto;
}

.player1 .player-grid,
.player2 .player-grid,
.player3 .player-grid,
.player4 .player-grid {
  justify-content: flex-start;
}

.player1 .player-bet,
.player2 .player-bet,
.player3 .player-bet,
.player4 .player-bet {
  border-radius: 12px 0px 0px 12px;
}

.player1 .player-card,
.player2 .player-card,
.player3 .player-card,
.player4 .player-card {
  padding-left: 0px;
  padding-right: 50px;
}

.player1 .player-stack,
.player2 .player-stack,
.player3 .player-stack,
.player4 .player-stack {
  right: 0;
  left: auto;
}

.player1 .player-bet h4,
.player2 .player-bet h4,
.player3 .player-bet h4,
.player4 .player-bet h4 {
  padding-left: 0px;
  padding-right: 50px;
}

.player1 .player-bet span,
.player2 .player-bet span,
.player3 .player-bet span,
.player4 .player-bet span {
  padding-left: 0px;
  padding-right: 45px;
}

.add-player {
  background: #373940;
  border: 3px solid #23262e;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 94px;
  cursor: pointer;
  margin: 0px auto;
}

.player5 .player-action {
  left: 150px;
  top: -215px;
}

.player6 .player-action {
  left: 275px;
  top: -90px;
}

.player7 .player-action {
  left: 275px;
  bottom: -90px;
}

.player8 .player-action {
  left: 150px;
  top: 200px;
}

.player1 .player-action {
  right: 150px;
  top: 200px;
}

.player2 .player-action {
  right: 275px;
  bottom: -90px;
}

.player3 .player-action {
  right: 275px;
  top: -90px;
}

.player4 .player-action {
  right: 150px;
  top: -215px;
}

.player.active .player-pic {
  border-color: #c7ad7a;
}

.player-pic .btn {
  background: transparent;
  border-color: transparent;
  padding: 0;
  border: none;
}

.player-pic .btn:hover,
.player-pic .btn:focus,
.player-pic .btn-primary:active:focus,
.player-pic .show > .btn-primary.dropdown-toggle,
.player-pic .show > .btn-primary.dropdown-toggle:focus {
  background: transparent;
  border-color: transparent;
  padding: 0;
  box-shadow: none;
}

.player-pic .dropdown-toggle::after {
  display: none;
}

.player-pic .dropdown-menu {
  background: #454851;
  padding: 0;
}

.player-pic .dropdown-menu a {
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #d93355;
}

.player-pic .dropdown-menu a:first-child {
  color: #fff;
}

.player-pic .dropdown-menu a:hover,
.player-pic .dropdown-menu a:focus {
  background: transparent;
}

/*************************** Pot Card *******************************/

.pot-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -120%);
}

.pot-grid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pot-grid img {
  margin: 0px 3px;
  max-width: 65px;
  height: 96px;
}

.pot-count {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  padding-top: 10px;
}

.pot-count h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;

  /* Grayscale/Gray */
  color: #a0aabb;
  margin: 0;
}

.pot-count h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height, or 130% */
  text-align: center;
  text-transform: uppercase;

  /* Grayscale / White */
  color: #ffffff;
  margin: 0;
}

/*************************** Player Action *******************************/

.player-action {
  position: absolute;
  z-index: 9;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.player-action-box {
  position: relative;
  text-align: center;
  border-radius: 4px;
}

.player-action-box h4 {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding: 1px 16px;
}

.player-action-box-text img {
  width: 23px;
  margin-right: 4px;
}

.player-action-box-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: "DDCHardware" !important;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.player-action-box.check-action {
  border: 1px solid #5b72e2;
}

.player-action-box.check-action h4 {
  background: #5b72e2;
}

.player-action-box.all-action {
  border: 1px solid #d93355;
}

.player-action-box.all-action h4 {
  background: #d93355;
}

.player-action-box.call-action {
  border: 1px solid #ddab34;
}

.player-action-box.call-action h4 {
  background: #ddab34;
}

.player-action-box.raise-action {
  border: 1px solid #57b661;
}

.player-action-box.raise-action h4 {
  background: #57b661;
}

.player-action-box.bigblind-action {
  border: 1px solid #d7905d;
}

.player-action-box.bigblind-action h4 {
  background: #d7905d;
}

.player-action-box.smallblind-action {
  border: 1px solid #7c5dd7;
}

.player-action-box.smallblind-action h4 {
  background: #7c5dd7;
}

.player-action-fold {
  background: #373940;
  padding: 9px 16px;
  border-radius: 4px;
}

.player-action-fold h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.player-action-check {
  background: #5b72e2;
  padding: 9px 16px;
  border-radius: 4px;
}

.player-action-check h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

/*************************** Action Button *******************************/

.action-button {
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 173px;
  width: 100%;
}

.action-button .btn,
.action-button .btn:hover {
  border: 1px solid #c7ad7a;
  border-radius: 6px;
  background: transparent;
  max-width: 173px;
  height: 50px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #c7ad7a;
  font-family: "DDCHardware" !important;
}

.action-button .btn:focus,
.action-button .btn-primary:active:focus,
.action-button .btn:focus:hover,
.action-button .btn:focus-visible,
.action-button .btn-primary:active,
.action-button .btn-check:checked + .action-button .btn,
.action-button .btn.active,
.action-button .btn.show,
.action-button .btn:first-child:active,
:not(.action-button .btn-check) + .action-button .btn:active {
  border: 1px solid #454851;
  border-radius: 6px;
  background: transparent;
  box-shadow: 0 0 0 3px #454851 !important;
  color: #c7ad7a;
}

.scan-btn {
  margin-bottom: 12px;
}

.action-button .btn:hover {
  color: #af935d;
  border: 1px solid #af935d;
}

/*************************** Modals *******************************/

.poker-popup .modal-content {
  border-color: #23262e;
  background: #23262e;
  box-shadow: 10px 10px 60px #1b1d23;
  border-radius: 20px;
}

.poker-popup .modal-header {
  border: none;
  padding: 16px 65px 16px 20px;
  position: relative;
}

.poker-popup .modal-body {
  padding: 10px 20px;
}

.poker-popup .modal-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "DDCHardware" !important;
}

.poker-popup .modal-header .btn-close {
  background: url(../../../assets/images/dealer/close-icon.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  opacity: 1;
  margin: 0;
  position: absolute;
  right: 3%;
}

.poker-popup .modal-header .btn-close:focus {
  box-shadow: none;
}

/*************************** Change Stack *******************************/

.change-stack {
  padding: 2px 0px 0px;
}

.change-stack .form-control,
.change-stack .form-control:focus {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  box-shadow: none;
}

.change-stack .form-label {
  font-size: 14px;
  line-height: 16px;
  display: block;
  color: #a0aabb;
  margin: 0;
  padding-bottom: 10px;
}

.change-stack .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.change-stack .form-control::-moz-placeholder {
  color: #ffffff;
}

.change-stack .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.change-stack .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.change-stack .form-control::placeholder {
  color: #ffffff;
}

.stack-popup .modal-dialog {
  max-width: 312px;
}

.change-stack .input-range {
  margin-top: 40px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.stack-btn {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.stack-btn .btn {
  width: 100%;
  height: 50px;
  background: #c7ad7a;
  border-radius: 6px;
  border-color: #c7ad7a;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  font-family: "DDCHardware" !important;
  transition: all 0.5s;
}

.stack-btn .btn:hover,
.stack-btn .btn-check:checked + .stack-btn .btn,
.stack-btn .btn.active,
.stack-btn .btn.show,
.stack-btn .btn:first-child:active,
:not(.stack-btn .btn-check) + .stack-btn .btn:active,
.stack-btn .btn:focus-visible {
  background: #af935d;
  border-color: #af935d;
  box-shadow: none;
}

.stack-btn .btn:focus,
.stack-btn .btn:focus:not(:focus-visible),
.stack-btn .btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.stack-btn .btn:disabled,
.stack-btn .btn.disabled {
  color: #31343e !important;
  border-color: #454851 !important;
  background: #454851 !important;
  opacity: 1 !important;
}

.change-stack .stack-btn .btn,
.change-stack .stack-btn .btn:hover,
.change-stack .stack-btn .btn:focus,
.change-stack .stack-btn .btn-primary:active:focus {
  width: 48%;
}

.change-stack .stack-btn .btn:first-child {
  background: transparent;
  border-color: #af935d;
  color: #af935d;
  box-shadow: none;
}

.change-stack .input-range * {
  font-family: "Gilroy" !important;
}

.input-range__track {
  height: 8px;
  background: #a0aabb;
  border-radius: 4px;
}

.change-stack .input-range__track--active {
  background: #af935d;
}

.change-stack .input-range__slider {
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 15%);
  width: 24px;
  height: 24px;
  margin-top: -16px;
}

.change-stack .input-range__label-container {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0aabb;
}

.change-stack
  .input-range__label.input-range__label--value
  .input-range__label-container {
  background: #4c505e;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 6px;
}

.change-stack .input-range__label.input-range__label--value {
  top: -3.5rem;
}

.change-stack .input-range__label--min {
  left: 4px;
}

.change-stack .input-range__label--min,
.change-stack .input-range__label--max {
  top: -18px;
  position: absolute;
}

.change-stack .input-range__label--max {
  right: 15px;
}

.change-stack
  .input-range__label.input-range__label--value
  .input-range__label-container:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #4c505e;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -5px;
}

/*************************** Invite User *******************************/

.invite-popup .modal-dialog {
  max-width: 355px;
}

.invite-user {
  max-height: 400px;
  overflow-y: auto;
}

.invite-user::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #373940;
}

.invite-user::-webkit-scrollbar {
  width: 0px;
  background-color: #373940;
}

.invite-user::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #373940;
}

.invite-user-list {
  display: flex;
  padding: 4px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  transition: all 0.3s;
}

.invite-user-list:hover {
  background: #4c505e;
}

.invite-user-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invite-user-cover {
  width: 40px;
  height: 40px;
  background: #373940;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
}

.invite-user-cover img {
  width: 32px;
  height: 32px;
  -o-object-fit: fill;
  object-fit: fill;
  border-radius: 100%;
}

.invite-user-info p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0;
  padding-left: 10px;
}

.invite-user-action span {
  cursor: pointer;
}

.online-user {
  background: #83bc9a;
  border: 0.761062px solid #373940;
  border-radius: 38.0531px;
  width: 7px;
  height: 7px;
  position: absolute;
  left: 2px;
  top: 5px;
}

.invite-add-btn {
  margin: 16px 0px 10px;
}

.invite-add-btn .btn,
.invite-add-btn .btn:hover,
.invite-add-btn .btn:focus {
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: "DDCHardware" !important;
  background: #c7ad7a;
  border-radius: 6px;
  border-color: #c7ad7a;
  box-shadow: none;
}
.invite-add-btn .btn:hover {
  background: #af935d;
  border-color: #af935d;
}

.invite-add-btn .btn:disabled,
.invite-add-btn .btn.disabled {
  color: #31343e !important;
  border-color: #454851 !important;
  background: #454851 !important;
  opacity: 1 !important;
}

.add-check-box .form-check-input {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 3.5px !important;
  width: 20px;
  height: 20px;
  margin: 0;
  vertical-align: middle;
  cursor: pointer;
}

.add-check-box .form-check-input:focus {
  box-shadow: none;
  border: 1px solid #a0aabb;
}

.add-check-box .form-check-input:checked,
.add-check-box .form-check-input[type="radio"] {
  background: transparent;
  background-image: none !important;
  position: relative;
  border: 1px solid #a0aabb;
}

.add-check-box .form-check-input:checked:before {
  content: "";
  background-image: url(../../../assets/images/checkTick.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 18px;
  height: 18px;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
/*************************** Set Chips *******************************/

.set-chips-popup .modal-dialog {
  max-width: 312px;
}

.set-chips .form-label,
.set-chip-radio label.form-check-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #a0aabb;
  margin: 0;
  margin-bottom: 9px;
}

.set-chip-radio label.form-check-label {
  margin: 0;
}

.set-chips .form-control {
  margin-bottom: 12px;
}

.set-chips {
  margin-top: -10px;
}

.set-chips .form-control,
.set-chips .form-control:focus {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  box-shadow: none;
}

.set-chips .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.set-chips .form-control::-moz-placeholder {
  color: #ffffff;
}

.set-chips .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.set-chips .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.set-chips .form-control::placeholder {
  color: #ffffff;
}

.set-chips-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 144px;
  width: 100%;
  background: #3e414c;
  top: 63%;
}

.set-chips-btn span {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #a0aabb;
  border-radius: 4px;
  border: 1px solid #576266;
  display: block;
  max-width: 144px;
  width: 100%;
  height: 44px;
  line-height: 40px;
  font-family: "DDCHardware" !important;
  cursor: pointer;
  transition: all 0.5s;
}

.set-chips-btn span:hover {
  background: rgba(35, 38, 46, 0.7);
}

/*************************** QR Scanner *******************************/

.card-scaning-popup .modal-dialog.modal-dialog-centered {
  width: 762px;
}

.qr-scanner {
  position: relative;
  background-color: #2c2f39;
  max-width: 100%;
  width: 100%;
  height: 405px;
  border-radius: 8px;
  overflow: hidden;
}

.qr-scanner canvas {
  height: 100% !important;
  width: 100% !important;
  -o-object-fit: contain;
  object-fit: contain;
}

.modal-backdrop.show {
  opacity: 0.2 !important;
}

.card-scaning-popup .btn {
  background: #c7ad7a;
  border-color: #c7ad7a;
  width: 100%;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  font-family: "DDCHardware" !important;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.card-scaning-popup .btn:hover,
.card-scaning-popup .btn:focus,
.card-scaning-popup .btn:focus-visible,
.card-scaning-popup .btn-check:checked + .card-scaning-popup .btn,
.card-scaning-popup .btn.active,
.card-scaning-popup .btn.show,
.card-scaning-popup .btn:first-child:active,
:not(.card-scaning-popup .btn-check) + .card-scaning-popup .btn:active {
  box-shadow: none;
  background: #af935d;
  border-color: #af935d;
}

.camera-not-found {
  background-color: #23262e;
  max-width: 100%;
  width: 100%;
  height: 300px;
  border-radius: 8px;
  border: 1px solid #4c505e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera-not-found h4 {
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #4c505e;
  padding-bottom: 12px;
  margin: 0;
}

.camera-not-found p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #4c505e;
  text-align: center;
}

.scan-cards {
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
}

.scan-cards span {
  display: flex;
  margin: 0px 3px;
  background: #31343e;
  border-radius: 3px;
  width: 65px;
  height: 97px;
}

.scan-cards span img {
  width: 65px;
  height: 97px;
  -o-object-fit: cover;
  object-fit: cover;
}

.dealer-companion .btn:disabled,
.dealer-companion .btn.disabled {
  color: #454851 !important;
  border-color: #454851 !important;
  background: transparent !important;
  opacity: 1 !important;
}

/************************** Set chip radio ****************************/

.set-chip-radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.set-chip-radio .form-check {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.set-chip-radio .form-check-input[type="radio"] {
  position: unset;
  margin-left: unset !important;
}

.set-chip-radio .form-check-input:checked {
  background-color: #c7ad7a;
  border: none;
}

.set-chip-radio .form-check-input:focus {
  box-shadow: none;
}

/***************** reset button **************************/

/*.poker-table .reset-button {
    top: unset;
    bottom: 85px;
}


.poker-table .change-blind-button {
    top: unset;
    bottom: 145px;
}*/

.set-action-btn {
  top: unset;
  bottom: 82px;
}

/***************** Side / Main pot ******************/

.main-pot-count {
  top: -185px;
}

.side-pot-1 {
  top: -65px;
}

.side-pot-2 {
  top: 98px;
}

.pot-count .side-pot-amount {
  display: flex;
  align-items: center;
  -moz-column-gap: 15px;
  column-gap: 15px;
}

.dealer-wrapper {
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0) scale(100%);
  transition: all 0.5s;
}

.notification-wrapper {
  position: fixed;
  top: 50px;
  right: -132px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fade.poker-popup.card-scaning-popup,
.fade.poker-popup.community-cam-setup-modal {
  left: 50%;
  transform: translate(-50%, 0) scale(100%);
}

/*********************** All Pot amount ************************/
.all-player-pot-wrapper {
  max-width: 108px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  position: absolute;
  bottom: 30%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.all-player-pot-wrapper h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #a0aabb;
  margin: 0;
  letter-spacing: 0.04rem;
}

.total-pot-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 6px;
  width: 100%;
}

.total-pot-container h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 0.04rem;
  margin: 0;
  white-space: nowrap;
}

/******************************** Community Card Cam Modal Start ****************************/

.community-cam-setup-main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.community-cam-setup-modal .modal-body {
  padding-bottom: 20px;
}

.community-cam-save-btn.btn {
  display: flex;
  width: 100%;
  padding: 15px 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: DDCHardware;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 6px;
  background: #c7ad7a;
  border-color: #c7ad7a;
}

.community-cam-save-btn.btn-check:checked + .community-cam-save-btn.btn,
.community-cam-save-btn.btn.active,
.community-cam-save-btn.btn.show,
.community-cam-save-btn.btn:first-child:active,
:not(.community-cam-save-btn.btn-check) + .community-cam-save-btn.btn:active {
  background: #c7ad7a;
  border-color: #c7ad7a;
}

.community-cam-save-btn.btn:hover {
  background: #af935d;
  border-color: #af935d;
}

.community-cam-save-btn.btn:focus,
.community-cam-save-btn.btn:focus-visible {
  background: #c7ad7a;
  border-color: #c7ad7a;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
  outline: 0;
}

.community-cam-setup-option-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.community-cam-setup-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #a0aabb;
}

.community-cam-setup-input {
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #a0aabb;
  background: #454851;
  cursor: pointer;
}

.community-cam-setup-input p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #fff;
  margin: 0;
}

.community-cam-setup-main .drop-wrapper {
  border-radius: 6px;
  background: #454851;
  box-shadow: 0px 10px 60px 0px #1b1d23;
  top: 62px;
  max-height: 136px;
  overflow-y: auto;
}

.community-cam-view-wrapper video {
  width: 720px !important;
  height: 405px;
  -o-object-fit: contain;
  object-fit: contain;
}

.community-cam-view-wrapper {
  position: relative;
  width: 720px;
  height: 405px;
  border-radius: 8px;
  border: 1px solid #4c505e;
  overflow: hidden;
}

.community-cam-view-wrapper::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../../assets/images/newCamFrame.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 718px;
  height: 405px;
}

.community-zoom-wrapper {
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 9;
}

.community-zoom-wrapper span {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: radial-gradient(
      341.25% 341.25% at 54.33% -118.75%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(87, 98, 102, 0.5);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  cursor: pointer;
}

.community-cam--frame-info {
  position: absolute;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  top: 49%;
  left: 50%;
  transform: translate(-50%, 60%);
  color: #fff;
  width: 278px;
  z-index: 1;
}

.community-cam-setup-modal .modal-dialog.modal-dialog-centered {
  width: 762px !important;
  max-width: 762px !important;
}

/******************************** Community Card Cam Modal End ****************************/

/********************************* Dealer waiting rail viewer START *****************************/

.waiting-rail-main-wrapper {
  position: relative;
}

.manage-waiting-rail-modal {
  position: absolute;
  right: 0;
  top: 50px;
  transform: translateY(-8px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.manage-waiting-rail-modal.show {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.waiting-rail-modal-wrapper {
  display: flex;
  width: 380px;
  background: #31343e;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 10px 60px 0px #1b1d23;
  overflow: hidden;
}

.waiting-rail-tab-head {
  display: flex;
  padding: 16px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.waiting-rail-tab-btn-wrapper {
  display: flex;
  height: 32px;
  align-items: center;
  border-radius: 12px;
  background: #454851;
  width: 100%;
}

.waiting-rail-tab-btn-wrapper span {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  border-radius: 12px;
  transition: all 0.3s;
}

.waiting-rail-tab-btn-wrapper span.active {
  font-weight: 400;
  color: #23262e;
  background: #fff;
}

.waiting-rail-tab-content-wrapper {
  width: 100%;
  min-height: 483px;
}

.invite-user-action {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.rail-list-btns {
  flex-direction: column;
  gap: 8px;
}

.rail-audio-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
}

.no-bg-btn.btn,
.no-bg-btn.btn:hover,
.no-bg-btn.btn:active,
.no-bg-btn.btn:focus,
.no-bg-btn.btn:focus-visible,
.no-bg-btn.btn:first-child:active,
:not(.no-bg-btn.btn-check) + .no-bg-btn.btn:active {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  color: #c7ad7a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background: transparent;
  border: transparent;
}

.no-bg-btn.btn:disabled,
.no-bg-btn.btn.disabled {
  background: transparent !important;
  border: transparent !important;
  color: #c7ad7a !important;
  opacity: 0.5 !important;
}

.no-bg-btn.btn svg path {
  fill: #c7ad7a;
}

.no-bg-btn.btn:first-child svg path:last-child {
  fill: #d6002b;
}

/********************************* Dealer waiting rail viewer END *****************************/

/**********************************************************************************/
/*************************************** Media Query ***************************************/
/**********************************************************************************/

/*@media screen and (max-width: 1440px) {
  .poker-table {
    width: 1100px;
    height: 700px;
  }

  .player2,
  .player7 {
    top: 130px;
  }

  .player3,
  .player6 {
    bottom: 245px;
  }

  .player4,
  .player1 {
    right: 18%;
  }

  .player5,
  .player8 {
    left: 18%;
  }

  .action-button .btn,
  .action-button .btn:hover {
    height: 40px;
  }

  .set-action-btn {
    top: unset;
    bottom: 62px;
  }

  .pot-grid img {
    height: 80px;
  }

  .player1 .player-action {
    right: 130px;
    top: 143px;
  }

  .player2 .player-action {
    right: 227px;
    bottom: -64px;
  }

  .player3 .player-action {
    right: 238px;
    top: -50px;
  }

  .player4 .player-action {
    right: 140px;
    top: -140px;
  }

  .player5 .player-action {
    left: 140px;
    top: -140px;
  }

  .player6 .player-action {
    left: 239px;
    top: -50px;
  }

  .player7 .player-action {
    left: 227px;
    bottom: -64px;
  }

  .player8 .player-action {
    left: 134px;
    top: 143px;
  }

  .set-chips-btn span {
    height: 40px;
  }

  .main-pot-count {
    top: -162px;
  }

  .side-pot-1 {
    top: -60px;
  }

  .side-pot-2 {
    top: 77px;
  }

  .poker-table:before {
    height: 400px;
  }
}*/

/*@media screen and (max-width: 1366px) {
  .poker-table {
    width: 890px;
    height: 567px;
  }
  .set-chips-btn {
    top: 62%;
  }
  .player {
    max-width: 180px;
  }
  .player2,
  .player7 {
    top: 110px;
  }

  .player3,
  .player6 {
    bottom: 220px;
  }
  .player-cover {
    width: 80px;
  }

  .player-content {
    width: calc(100% - 30px);
  }

  .player-stack img {
    width: 15px;
  }

  .player-stack {
    width: 24px;
    height: 24px;
    line-height: 22px;
  }

  .player-pic {
    width: 80px;
    height: 80px;
    border: 2px solid #23262e;
  }

  .player-pic img {
    width: 76px;
    height: 76px;
  }

  .player-card {
    top: -35px;
  }
  .player-card img {
    max-width: 40px;
  }

  .player-bet h4 {
    font-size: 22px;
  }

  .player-bet span {
    font-size: 16px;
    padding: 5px 10px;
  }

  .scan-btn {
    margin-bottom: 8px;
  }
  .action-button .btn,
  .action-button .btn:hover {
    font-size: 14px;
  }

  .poker-table .reset-button {
    bottom: 87px;
  }

  .player-action-box h4 {
    font-size: 14px;
    line-height: 20px;
    padding: 1px 14px;
  }

  .player1 .player-action {
    right: 112px;
    top: 128px;
  }

  .player2 .player-action {
    right: 194px;
    bottom: -57px;
  }

  .player3 .player-action {
    right: 193px;
    top: -35px;
  }

  .player4 .player-action {
    right: 100px;
    top: -130px;
  }

  .player5 .player-action {
    left: 100px;
    top: -130px;
  }

  .player6 .player-action {
    left: 191px;
    top: -35px;
  }

  .player7 .player-action {
    left: 194px;
    bottom: -57px;
  }

  .player8 .player-action {
    left: 113px;
    top: 128px;
  }

  .pot-card {
    transform: translate(-50%, -127%);
  }

  .pot-grid img {
    height: 62px;
  }

  .main-pot-count {
    top: -119px;
  }

  .main-pot-count {
    top: -136px;
  }

  .side-pot-1 {
    top: -55px;
  }

  .side-pot-2 {
    top: 58px;
  }

  .set-action-btn {
    top: unset;
    bottom: 39px;
  }

  .add-player {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .player1 {
    top: -20px;
  }

  .player2 {
    right: -9px;
  }

  .player3 {
    right: -14px;
  }

  .player4,
  .player5 {
    bottom: 74px;
  }

  .player6 {
    left: -14px;
  }

  .player7 {
    left: -8px;
  }

  .player8 {
    top: -20px;
  }

  .poker-table:before {
    height: 340px;
  }

  .pot-count h4 {
    font-size: 16px;
  }

  .pot-count .side-pot-amount {
    column-gap: 10px;
  }
}*/

/*@media screen and (max-width: 1280px) {
  .player7 .player-action {
    left: 184px;
  }

  .player6 .player-action {
    left: 189px;
  }

  .player5 .player-action {
    left: 112px;
    top: -120px;
  }

  .player4 .player-action {
    right: 112px;
    top: -120px;
  }

  .player3 .player-action {
    right: 190px;
    top: -35px;
  }

  .player2 .player-action {
    right: 185px;
  }
}*/

.game-page {
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  min-height: calc(100vh - 70px);
  padding-top: 36px;
}

.create-game-grid {
  background: #31343e;
  box-shadow: 10px 10px 60px #1b1d23;
  border-radius: 20px;
  padding: 32px 50px;
  max-width: 422px;
  margin: 0px auto;
  margin-bottom: 60px;
  display: none;
}

.create-game-grid h2 {
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  margin: 0;
  text-align: center;
  padding-bottom: 32px;
  color: #ffffff;
}

.create-game-grid .btn {
  background: #c7ad7a;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  border-color: #c7ad7a;
  font-family: "DDCHardware" !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  transition: all 0.5s;
}

.create-game-grid .btn:hover,
.create-game-grid .btn:focus,
.create-game-grid :not(.btn-check) + .btn:active,
.create-game-grid .btn:focus-visible {
  background: #af935d;
  border-color: #af935d;
  outline: 0;
}

.create-game-grid .btn:focus,
.create-game-grid .btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.poker-night h2 {
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  margin: 0;

  text-align: center;
  padding-bottom: 20px;
  background: #23262e;

  color: #ffffff;
}
.poker-night h3 {
  font-style: normal;
  font-weight: 410;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding-bottom: 20px;
}

.game-card-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 32px;
}

.game-card {
  background: #31343e;
  box-shadow: 10px 10px 60px #1b1d23;
  border-radius: 20px;
  /*padding: 24px 20px;*/
  max-width: 314px;
  width: 100%;
}
.game-name-grid {
  /*display: flex;
    gap: 12px;*/
  display: flex;
  gap: 12px;
  padding: 20px;
  border-bottom: 1px solid #454851;
}
.game-name-grid img {
  width: 44px;
  height: 44px;
  border-radius: 8px;
}
.game-name {
  display: grid;
  gap: 4px;
}
.game-card h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  text-align: start;
}

.game-card h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #a0aabb;
  margin: 0;
}

/*.game-card h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #a0aabb;
  margin: 0;
  padding-bottom: 16px;
}*/

.game-card span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;

  /* Grayscale/Gray */
  color: #a0aabb;
  display: block;
  padding-bottom: 4px;
}

.game-card p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;

  /* Grayscale/Gray Light */
  color: #909090;
  max-width: 250px;
  margin: 0px auto;
}

.game-user-box {
  padding-top: 16px;
  display: flex;
  justify-content: center;
  display: none;
}

.game-user {
  margin: 0px 2px;
}

.game-user img {
  width: 32px;
  height: 32px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 100%;
}

.game-bottom-grid {
  padding: 20px;
}
.game-user-table h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 4px;
}

.game-card .game-user-table h5 span {
  font-weight: 410;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 0;
  display: unset;
}
.game-btn {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  /*padding: 20px;*/
}

.game-btn .btn {
  background: #c7ad7a;
  border-radius: 10px;
  /*width: calc(100% - 66px);*/
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  border-color: #c7ad7a;
  font-family: "DDCHardware" !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  transition: all 0.5s;
}

.game-btn .btn:hover,
.game-btn .btn:focus,
.game-btn .btn-check:checked + .game-btn .btn,
.game-btn .btn.active,
.game-btn .btn.show,
.game-btn .btn:first-child:active,
:not(.game-btn .btn-check) + .game-btn .btn:active,
.game-btn .btn:focus-visible {
  background: #af935d;
  border-color: #af935d;
  outline: 0;
}
.game-btn .btn:focus,
.game-btn .btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}
.game-btn .delete-icon {
  border: 1px solid #c7ad7a;
  border-radius: 6px;
  background: transparent;
  width: 50px;
  display: none;
}

.game-btn .delete-icon:hover,
.game-btn .delete-icon:active,
:not(.game-btn .delete-icon.btn-check) + .game-btn .delete-icon.btn:active {
  background: transparent;
  border: 1px solid #af935d;
  box-shadow: none;
}

.game-btn .delete-icon:focus,
.game-btn .delete-icon:focus-visible,
.game-btn :not(.btn-check) + .btn:active {
  background: transparent;
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

/*************** Generate game **************/

.generate-content {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.generate-form {
  background: #31343e;
  /* box-shadow: 10px 10px 60px #1B1D23; */
  border-radius: 20px;
  max-width: 426px;
  width: 100%;
  margin: 0px auto;
  padding: 32px 50px;
  max-height: 864px;
  overflow-y: scroll;
}

.game-genarate-modal .modal-body {
  padding: 0;
}

.game-genarate-modal .modal-dialog {
  max-width: 426px;
}

.game-genarate-modal .modal-content {
  border-radius: 20px;
  border: none;
  background: #31343e;
}

.generate-form-head h2 {
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  /*padding-bottom: 12px;*/
  padding-bottom: 32px;
  text-align: center;
  margin: 0;
  color: #ffffff;
}

.generate-form-head p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;

  /* Grayscale/Gray Light */
  color: #909090;
  padding-bottom: 32px;
  margin: 0;
}

.generate-form-head span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #909090;
  padding-bottom: 20px;
}

.generate-form .form-label,
.add-company-logo h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a0aabb;
  margin-bottom: 10px;
  text-transform: unset;
  font-family: "Gilroy" !important;
}
.generate-form .form-control:disabled {
  background: #454851;
  border: 1px solid #a0aabb;
  opacity: 0.5;
}
.generate-form .form-control {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  padding: 5px 12px;
}
.generate-form .form-control:focus {
  box-shadow: none;
  border: 1px solid #ffffff;
  background: #454851;
  color: #ffffff;
}

.generate-form .form-control::-webkit-input-placeholder {
  color: #a5a5a5;
}

.generate-form .form-control::-moz-placeholder {
  color: #a5a5a5;
}

.generate-form .form-control:-ms-input-placeholder {
  color: #a5a5a5;
}

.generate-form .form-control::-ms-input-placeholder {
  color: #a5a5a5;
}

.generate-form .form-control::placeholder {
  color: #a5a5a5;
}

.generate-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.generate-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.generate-btn .btn:last-child {
  background: transparent;
  color: #c7ad7a;
}

.generate-btn .btn {
  background: #c7ad7a;
  border-radius: 6px;
  border-color: #c7ad7a;
  width: 47%;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  font-family: "DDCHardware" !important;
  transition: all 0.5s;
}
.generate-btn .btn:last-child:hover,
.generate-btn .btn:last-child:focus,
.generate-btn .btn-check:last-child:checked + .generate-btn .btn:last-child,
.generate-btn .btn.active:last-child,
.generate-btn .btn.show:last-child,
.generate-btn .btn:first-child:active,
:not(.generate-btn .btn-check:last-child)
  + .generate-btn
  .btn:last-child:active,
.generate-btn .btn:last-child:focus-visible {
  background: transparent;
  border-color: #af935d;
  color: #af935d;
  outline: 0;
}
.generate-btn .btn:focus,
.generate-btn .btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}
.generate-btn .btn:last-child:focus,
.generate-btn .btn:last-child:focus-visible {
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.generate-btn .btn:hover,
.generate-btn .btn:focus,
.generate-btn .btn-check:checked + .generate-btn .btn,
.generate-btn .btn.active,
.generate-btn .btn.show,
.generate-btn .btn:first-child:active,
:not(.generate-btn .btn-check) + .generate-btn .btn:active,
.generate-btn .btn:focus-visible {
  background: #af935d;
  border-color: #af935d;
  outline: 0;
}

.generate-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  gap: 8px;
}

.generate-box .btn {
  width: 40px;
  height: 40px;
  padding: 0;
  background: #c7ad7a;
  border-radius: 6px;
  border-color: #c7ad7a;
  margin-top: 10px;
  transition: all 0.5s;
}
.generate-box .btn:hover,
.generate-box .btn:focus,
.generate-box .btn-check:checked + .generate-box .btn,
.generate-box .btn.active,
.generate-box .btn.show,
.generate-box .btn:first-child:active,
:not(.generate-box .btn-check) + .generate-box .btn:active,
.generate-box .btn:focus-visible {
  background: #af935d;
  border-color: #af935d;
  outline: 0;
}
.generate-box .btn:focus,
.generate-box .btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.generate-box .form-group {
  width: calc(100% - 50px);
}

.css-1s2u09g-control {
  background: #454851 !important;
  border: 1px solid #a0aabb !important;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
}

/********* generate step form *********/
.generate-step-form .half-width.generate-box .form-group {
  width: 48.5%;
}
.generate-step-form .generate-form-head h2 {
  padding-bottom: 32px;
}
.generate-step-form .generate-form-head p {
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  padding-bottom: 20px;
}
.generate-step-form .bind-structure h6 {
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  text-align: center;
}
.generate-step-form .bind-structure button.slick-arrow.slick-prev::before {
  content: "" !important;
  background: url("../../../assets/images/dealer/leftarrow.svg");
  height: 26px;
  width: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 12px;
}
.generate-step-form .bind-structure button.slick-arrow.slick-next::before {
  content: "" !important;
  background: url("../../../assets/images/dealer/rightarrow.svg");
  height: 26px;
  width: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
}
.generate-step-form
  .bind-structure
  button.slick-arrow.slick-disabled.slick-prev::before,
.generate-step-form
  .bind-structure
  button.slick-arrow.slick-disabled.slick-next::before {
  cursor: not-allowed;
}
.generate-step-form .bind-structure .bind-list {
  border-left: 1px solid #4c505e;
}
.generate-step-form .bind-structure .slick-list {
  width: 80%;
  margin: 0px auto;
}
.generate-step-form .bind-structure .slick-prev {
  left: -2px;
}
.generate-step-form .bind-structure .slick-next {
  right: 12px;
}
.bind-structure {
  padding: 8px 0px;
  width: 322px;
  height: 56px;
  background: #454851;
  border-radius: 6px;
}

.generate-step-form .bind-structure p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a0aabb;
  margin: 0;
  text-align: center;
}
.generate-step-form .bind-list {
  display: flex;
  justify-content: center;
}
.selected-img img {
  height: 32px;
  width: 32px;
  margin-right: 8px;
  border-radius: 50%;
}

.selected-img {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #454851;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #373940;
}
.generate-game-page .modal-backdrop.show {
  opacity: 1 !important;
}
.generate-step-form .select__indicators {
  display: none;
}

.generate-step-form
  .select__value-container.select__value-container--is-multi.select__value-container--has-value {
  justify-content: flex-start;
  align-items: center;
}
.generate-step-form .select__multi-value__remove {
  height: 20px;
  width: 20px;
  background: #ffffff;
  border-radius: 50%;
  margin-left: 10px;
}
.generate-step-form .select__multi-value {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.generate-step-form .select__multi-value__remove svg {
  fill: #000;
  height: 20px !important;
  width: 20px !important;
}

.generate-step-form .selected-user {
  padding: 8px 0px;
}
.generate-game-page .bind-structure p {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #a5a5a5;
}
.generate-form .select__multi-value {
  background: #373940;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 5px;
}
.generate-form .select__multi-value__remove,
.generate-form .select__multi-value__remove:hover {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border-radius: 50%;
  background: #ffffff;
}
.generate-form .select__multi-value__remove svg {
  fill: #4c505e;
}
.generate-form .select__multi-value__label {
  padding: 0;
}
.generate-form .select__indicators {
  display: none;
}
.create-game-grid .btn:disabled {
  background: #af9563;
  border: 1px solid #af9563;
}

.generate-box .copied-tooltip {
  position: absolute;
  top: -6px;
  right: -10px;
  color: #fff;
  background: #23262e;
  border-radius: 6px;
  font-size: 12px;
  padding: 4px 10px;
  -webkit-animation: toolTipAnimate 1.2s ease;
  animation: toolTipAnimate 1.2s ease;
}

@-webkit-keyframes toolTipAnimate {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes toolTipAnimate {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/************************** place holder css *********************************/
.dash-placeholder {
  display: grid;
  gap: 24px;
}
.dash-placeholder-content {
  height: calc(100vh - 204px);
  display: grid;
  place-items: center;
  text-align: center;
}
.dash-placeholder h4 {
  font-weight: 410;
  font-size: 44px;
  line-height: 46px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
.dash-placeholder p {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #a0aabb;
  margin: 0;
}

/***********************************************/
.react-datepicker__time-container .react-datepicker__time {
  background: #454851;
}

.react-datepicker__time-container {
  width: 115px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: #c7ad7a !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 115px;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #c7ad7a !important;
}
/****************************************************/
.generate-step-form .half-width.time-box {
  gap: 8px;
}
.generate-step-form .half-width.time-box .form-group {
  width: 194px;
}

.generate-step-form .half-width .time-zone-box .form-group {
  width: 120px;
}

.generate-step-form .half-width .time-zone-box .form-group .select__control {
  height: 40px;
  position: relative !important;
}
.time-zone-select {
  position: relative;
}

.time-zone-select .game-time-zone {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.generate-form
  .half-width
  .game-date-box
  .react-datepicker__input-container
  input,
.generate-form
  .full-width
  .game-date-box
  .react-datepicker__input-container
  input {
  height: 40px !important;
}

.generate-popup-game .intro-video-form {
  padding: 0;
  background: transparent;
  box-shadow: none;
}

.generate-popup-game .intro-video-form .intro-back-arrow {
  display: none;
}

.generate-popup-game .intro-video-form h2 {
  display: none;
}
.generate-popup-game .intro-video-form p {
  padding-bottom: 10px;
}
.generate-popup-game .intro-video-form .intro-outline-btn {
  display: none;
}
/******************************************************************/
.tables-structure {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}
.table-structure {
  background-color: transparent;
  width: 100%;
}
.table-structure .tables-list {
  border: 1px solid #4c505e;
  border-radius: 6px;
  width: 42px !important;
  height: 40px;
  display: flex !important;
  place-items: center;
  justify-content: center;
  font-family: "DDCHardware";
}
.table-structure .tables-list.selected {
  background-color: #c7ad7a;
}
.tables-list.active {
  background-color: #c7ad7a;
}

.table-structure .tables-list span {
  font-weight: 410;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
}
.generate-form-scroll {
  overflow: hidden;
  border-radius: 20px;
}
/*********************************************/
.generate-popup-game .intro-video-form p.intro-popup {
  display: none;
}

/***************** Add company logo ****************************/
.add-company-logo {
  margin-bottom: 32px;
}
.add-company-logo .intro-drag-video {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.add-company-logo .intro-drag-video p {
  margin: 0;
}
.add-company-logo .intro-uploading-list-content img {
  border: 1px solid #4c505e;
}
.add-company-logo .intro-uploading-video-list {
  margin: 0;
}
.add-company-logo .intro-uploading-list-info p {
  margin: 0;
}

.rail-main {
  display: flex;
  flex-direction: revert;
  align-items: center;
  padding: 6px 8px;
  background: #31343e;
  border-radius: 16px;
  min-height: 54px;
}

.tab-btns-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #454851;
  border-radius: 12px;
}

.tab-btn {
  width: 114px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  border-radius: 12px;
  transition: all 0.3s;
  cursor: pointer;
}

.tab-btn.active {
  font-weight: 500;
  color: #23262e;
  background: #ffffff;
}

.rail-collapsed-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.rail-collapsed-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  cursor: pointer;
  margin-left: -200px;
}

.rail-profile-icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.rail-profile-icon {
  border: 1px solid #373940;
  border-radius: 100px;
  overflow: hidden;
  background: #a0aabb;
}

.rail-profile-icon img {
  width: 40px;
  height: 40px;
}

.rail-expanded-content svg {
  transform: rotate(180deg);
}

.rail-collapsed-content span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  gap: 6px;
}

.rail-slider-wrapper {
  width: 100%;
  height: 0px;
  background: #31343e;
  border-radius: 16px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a0aabb;
  overflow: hidden;
  transition: all 0.3s;
}

.rail-slider-wrapper.rail-show {
  height: 180px;
  overflow: unset;
}

.rail-player-wrapper {
  height: 157px;
  width: 140px;
  background: #23262e;
  border-radius: 16px;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.rail-username svg {
  width: 12px;
  height: 12px;
}

.rail-player-wrapper.player-voice,
.rail-profile-icon.player-voice {
  border-color: #57b661;
}

.rail-player-wrapper.player-voice-1to1,
.rail-profile-icon.player-voice-1to1 {
  border-color: #00acee;
}

.rail-player-wrapper .player-box-iframe .main-player-camoff {
  background: transparent;
}

.rail-player-wrapper .player-box-iframe {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rail-player-wrapper .player-box-iframe .main-player-camoff img {
  width: 40px;
  height: 40px;
  border: 2px solid #373940;
  border-radius: 100px;
  -o-object-fit: cover;
  object-fit: cover;
}

.rail-username {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  width: 124px;
  background: rgba(35, 38, 46, 0.7);
  border-radius: 8px;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
}

.rail-username span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  position: relative;
  transition: all 0.3s;
}

.cam-mic-wrapper {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -6px;
}

.rail-username.mute span {
  padding-right: 24px;
}

.rail-slider-content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px;
  gap: 16px;
}
.rail-player-wrapper .player-dropdown {
  left: unset;
  right: 8px;
}

.rail-slider-main {
  width: 1560px;
}

.rail-slider-main .slick-arrow::before {
  display: none;
}

.rail-slider-main .slick-arrow::after {
  position: absolute;
  background: unset;
  content: "";
  font-family: unset;
  font-size: unset;
  line-height: unset;
  background-image: url("../../assets/images/Left.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
}

.rail-slider-main .slick-prev,
.rail-slider-main .slick-next {
  width: 32px;
  height: 32px;
}

.rail-slider-main .slick-prev {
  left: -52px;
}
.rail-slider-main .slick-next {
  right: -52px;
  transform: rotate(180deg);
}

.rail-slider-main .slick-disabled::after {
  opacity: 0.5;
}

.rail-player-wrapper video {
  width: 100%;
  height: 153px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 16px;
}

.rail-page .container {
  padding: 0;
}

.rail-end-call.btn,
.rail-end-call.btn:hover,
.rail-end-call.btn:active,
.rail-end-call.btn:first-child:active,
.rail-end-call.btn:focus,
.rail-end-call.btn:focus-visible {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--additional-red-pink, #d93355);
  border-color: var(--additional-red-pink, #d93355);
}

.rail-end-call.btn img {
  width: 24px;
  height: 24px;
}

.rail-slider-main .end-private-call {
  border-radius: 16px;
  overflow: hidden;
}

.profile-viewer.rail-profile-viewer {
  top: unset;
  bottom: 0;
  left: unset;
}

.game-view::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #555;
}

.game-view::-webkit-scrollbar {
  width: 0px;
  background-color: #555;
}

.game-view::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.player-game-view {
  color: #fff;
}

.player-game-view .container {
  max-width: 1600px !important;
  position: relative;
  min-height: 100vh;
}

/**************************** Sidebar ***************************/

.player-game-sidebar {
  background: #383b41;
  width: 122px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 40px 0px;
  transition: all 0.3s;
  z-index: 999;
  box-shadow: 10px 10px 60px #1b1d23;
}

.player-sidebar-logo {
  padding-bottom: 20px;
}

.player-game-sidebar.hide {
  left: -125px;
  box-shadow: none;
}

.player-game-sidebar .nav {
  height: 84vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.player-game-sidebar .nav::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #555;
}

.player-game-sidebar .nav::-webkit-scrollbar {
  width: 0px;
  background-color: #555;
}

.player-game-sidebar .nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.player-game-sidebar-menu .nav a,
.player-game-sidebar-menu .nav span {
  display: block;
  width: 100%;
  margin: 0px 20px;
  margin-top: 36px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  padding: 14px 10px;
}

.player-game-sidebar-menu .nav a:hover,
.player-game-sidebar-menu .nav span:hover {
  background: #454851;
  border-radius: 8px;
}

.player-game-sidebar-menu .nav a span,
.player-game-sidebar-menu .nav span span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #a0aabb;
  display: block;
  margin: 0;
  padding: 0;
  padding-top: 10px;
}

.player-sidebar-logo img {
  cursor: pointer;
}

/**************************** Top Bar ***************************/

.player-bid-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0px;
  width: 1576px;
  height: 110px;
}

.player-bid-box {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.player-bid {
  height: 50px;
  padding: 6px 20px;
  background: #373940;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.player-bid-grid {
  max-width: 1000px;
  width: 100%;
}

.player-bid-button {
  max-width: unset;
  width: unset;
  text-align: unset;
}

.player-bid-logo {
  max-width: 288px;
  width: 100%;
}
.player-bid-logo span {
  cursor: pointer;
}

.player-bid-info h4 {
  margin: 0;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.player-bid-info h3 {
  margin: 0;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: #909090;
  margin-left: 12px;
}

.timer-grid {
  display: flex;
  align-items: center;
}

.player-bid-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.player-bid-icon h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #909090;
  margin: 0;
}

.player-bid-icon .alert-timer {
  color: #d93355 !important;
}

.player-bid-icon span {
  margin-left: 12px;
}

.player-bid-info span {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  display: block;
  color: #a0aabb;
}

.player-bid-button .btn,
.player-bid-button .btn:disabled {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #c7ad7a;
  background: transparent;
  border: 1px solid #c7ad7a;
  border-radius: 6px;
  width: 250px;
  height: 50px;
  box-shadow: none;
  font-family: "DDCHardware" !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.player-bid-button .btn:hover,
.player-bid-button .btn:focus,
.player-bid-button .btn-check:checked + .player-bid-button .btn,
.player-bid-button .btn.active,
.player-bid-button .btn.show,
.player-bid-button .btn:first-child:active,
:not(.player-bid-button .btn-check) + .player-bid-button .btn:active,
.player-bid-button .btn:focus-visible {
  background: transparent;
  border-color: #af935d;
  color: #af935d;
  outline: 0;
}

.player-bid-button .btn:focus,
.player-bid-button .btn:focus-visible {
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.player-blind-timer-dropdown {
  position: absolute;
  top: 75px;
  width: 100%;
  left: 0;
  background: #373940;
  border-radius: 6px;
  padding: 16px 20px;
  z-index: 99;
  transition: all 0.3s;
  visibility: hidden;
  transform: translateY(-20px);
  opacity: 0;
}

.player-blind-timer-dropdown.show {
  visibility: visible;
  transform: translateY(0px);
  opacity: 1;
}

.player-blind-timer-dropdown h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding-bottom: 16px;
}

.player-blind-timer-dropdown h4 span {
  color: #83bc9a;
  margin-left: 6px;
  display: inline-block;
}

.player-blind-timer-dropdown table td {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a5a5a5;
  border: none;
  padding: 4px 10px;
}

.player-blind-timer-dropdown table tr td:first-child {
  padding-left: 0;
}

.player-blind-timer-dropdown table {
  margin: 0;
}

.player-blind-timer-table {
  max-height: 162px;
  margin: 0;
  overflow-y: scroll;
}

.player-blind-timer-dropdown table td span {
  color: #fff;
}

.player-blind-timer-dropdown table td .blin-td span {
  color: #83bc9a;
}

.player-blind-timer-dropdown table td:last-child {
  text-align: right;
}

.player-blind-timer-table::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #373940;
}

.player-blind-timer-table::-webkit-scrollbar {
  width: 0px;
  background-color: #373940;
}

.player-blind-timer-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #373940;
}

.player-bid.player-pot-count {
  padding: 6px 10px;
  min-height: 50px;
}

.player-pot-count ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  flex-wrap: wrap;
  position: relative;
}

.player-pot-count ul li {
  border-right: 1px solid #454851;
  width: 33%;
  padding: 0 2%;
}

.player-pot-count ul li span {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: block;
  text-align: center;
  color: #a0aabb;
}

.player-pot-count ul li:last-child {
  border: none;
}

.player-pot-count ul li h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.player-pot-count ul.more-pots {
  padding-left: 80px;
}

.player-pot-count ul.more-pots li {
  border-right: none;
}
.player-pot-count ul.more-pots li:first-child {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
}

.player-pot-count ul.more-pots li span {
  font-size: 10px;
  line-height: 11px;
}

.player-pot-count ul.more-pots li h4 {
  font-size: 15px;
  line-height: 14px;
}

.player-pot-count ul.more-pots li:first-child span {
  font-size: 14px;
  line-height: 16px;
}

.player-pot-count ul.more-pots li:first-child h4 {
  font-size: 24px;
  line-height: 30px;
}

/**************************** Player View ***************************/

.player-game-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1230px;
  height: 684px;
  gap: 20px;
  padding: 20px;
  transition: all 0.3s;
}

.player-game-view-grid {
  max-width: 315px;
  width: 100%;
  display: grid;
  gap: 4px;
  align-content: flex-start;
}

.player-game-dealing {
  max-width: 520px;
  width: 100%;
  position: relative;
  /*padding-top: 5px;*/
}

/**************************** Player ***************************/

.player-box-list {
  background: #373940;
  position: relative;
  /*border-radius: 6px;*/
  overflow: hidden;
}

.player-box {
  border: 4px solid #373940;
  border-radius: 6px;
  position: relative;
}

.player-length-4 .active-player {
  border: 4px solid #c7ad7a;
}

.player-length-4 .player-box-iframe.private-call {
  border: 4px solid #00acee;
}

.player-length-3 .active-player {
  border: 4px solid #c7ad7a;
}

.player-length-3 .player-box-iframe.private-call {
  border: 4px solid #00acee;
}

.player-length-2 .active-player {
  border: 4px solid #c7ad7a;
}

.player-length-2 .player-box-iframe.private-call {
  border: 4px solid #00acee;
}

.player-length-1 .active-player {
  border: 4px solid #c7ad7a;
}

.player-length-1 .player-box-iframe.private-call {
  border: 4px solid #00acee;
}

.microphone-active {
  border: 4px solid #00acee !important;
}
.player-box-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.player-box-action-grid {
  max-width: 165px;
  width: 100%;
  padding: 10px;
  border-top: 2px solid transparent;
}
.company-logo-grid {
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  align-items: center;
}

.main-dealer-symbol {
  background: #ffffff;
  box-shadow: 10px 10px 60px #1b1d23;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #31343e;
  font-family: "DDCHardware" !important;
}

.company-logo-section img {
  width: 36px;
  height: 36px;
  -o-object-fit: cover;
  object-fit: cover;
}

.player-game-right-view
  .player-box-grid
  .company-logo-grid
  .company-logo-section
  img {
  border-radius: 0px 0px 12px 0px;
}

.player-game-left-view
  .player-box-grid
  .company-logo-grid
  .company-logo-section
  img {
  border-radius: 0px 0px 0px 12px;
}

.player-box-view {
  width: calc(100% - 165px);
  position: relative;
}

.player-dropdown {
  position: absolute;
  z-index: 9;
  top: 11px;
  left: 11px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.player-dropdown .btn,
.player-dropdown .btn:hover,
.player-dropdown .btn:focus,
.player-dropdown .btn-check:checked + .player-dropdown .btn,
.player-dropdown .btn.active,
.player-dropdown .btn.show,
.player-dropdown .btn:first-child:active,
:not(.player-dropdown .btn-check) + .player-dropdown .btn:active,
.player-dropdown .btn:focus-visible {
  border: none;
  box-shadow: none;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #909090;
  border-radius: 100%;
  gap: 2px;
}

.player-dropdown .btn::after {
  display: none;
}

.player-dropdown .dropdown-menu {
  background: #31343e;
  border-color: #31343e;
  padding: 0;
  overflow: hidden;
}

.player-dropdown .dropdown-item {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 12px;
  cursor: pointer;
}

.player-dropdown .dropdown-item:focus,
.player-dropdown .dropdown-item:hover {
  background: #393c46;
  color: #fff;
}

.player-dropdown .btn span {
  display: flex;
  align-items: center;
  width: 3px;
  height: 3px;
  background: #fff;
  border-radius: 100%;
  transition: all 0.3s;
}
.player-btn-dropdown button {
  background-color: #00acee !important;
}

.dropdwon-btn-1to1 button {
  background-color: #d93355 !important;
}
.dot-flashing.dot-animate {
  -webkit-animation: dot-flashing 1s infinite linear alternate;
  animation: dot-flashing 1s infinite linear alternate;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.dot-flashing.dot-animate::before {
  -webkit-animation: dot-flashing 1s infinite alternate;
  animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.dot-flashing.dot-animate::after {
  -webkit-animation: dot-flashing 1s infinite alternate;
  animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.dot-flashing {
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -5px;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
}
.dot-flashing::after {
  left: 5px;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
}

@-webkit-keyframes dot-flashing {
  0% {
    background-color: #b1adad;
  }
  50%,
  100% {
    background-color: #ffffff;
  }
}

@keyframes dot-flashing {
  0% {
    background-color: #b1adad;
  }
  50%,
  100% {
    background-color: #ffffff;
  }
}

.player-box-user {
  /*background: linear-gradient(180deg, #333333db, #26282f);*/
  background: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 4px;
  width: calc(100% - 0px);
  bottom: 0px;
  justify-content: space-between;
  padding: 4px;
  /*left: 5px;*/
}
.player-box-user:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: radial-gradient(
      341.25% 341.25% at 54.33% -118.75%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(87, 98, 102, 0.5);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.player-box-user span {
  display: block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  background: #23262e;
  border-radius: 2px;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  transition: all 0.3s;
  z-index: 9;
}

.player-box-user h4 {
  margin: 0;
  font-weight: 400;
  font-size: 26px;
  line-height: 26px;
  position: relative;
}

.player-box-iframe {
  display: block;
  /*border: 5px solid #31343e;*/
  border-radius: 6px;
}

.player-box-iframe img {
  width: 100%;
  height: 184px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.player-box-card {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.player-box-card span {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  position: relative;
  z-index: 9;
}

.player-box-card span.show {
  visibility: visible;
  opacity: 1;
}

.player-box-card span img {
  width: 74px;
  height: 101px;
  -o-object-fit: cover;
  object-fit: cover;
  margin: 0px auto;
  display: block;
  border-radius: 3px;
  outline: 2px solid #a6a7aa;
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  position: relative;
  z-index: 9;
}

.player-box-card span.show img {
  visibility: visible;
  opacity: 1;
}
.player-action-view {
  background: #373940;
  border: 2px solid #23262e;
  border-radius: 3px;
  padding: 5px 10px;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.player-action-view-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  /*padding-bottom: 6px;*/
}

.player-action-view h4 {
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
}

.player-game-left-view .player-action-view {
  text-align: right;
}

.raise-action-view {
  border-color: #57b661;
}

.raise-action-view.recent-player {
  background: #57b661;
}
.bigblind-action-view {
  border-color: #d7905d;
}

.smallblind-action-view {
  border-color: #7c5dd7;
}
.smallblind-action-view.recent-player {
  background: #7c5dd7;
}

.allin-action-view {
  border-color: #d93355;
}
.allin-action-view.recent-player {
  background: #d93355;
}
.call-action-view {
  border-color: #ddab34;
}
.call-action-view.recent-player {
  background: #ddab34;
}
.check-action-view {
  border-color: #5b72e2;
}

.player-action-view-bet {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.player-action-view-bet span {
  display: inline-block;
  margin-left: 7px;
  font-weight: 400;
  font-size: 18.3474px;
  line-height: 23px;
  color: #ffffff;
}

.player-action-view-fold {
  background: #454851;
  border: 2px solid #454851;
  border-radius: 2px;
  padding: 10px 8px;
  margin-top: 7px;
  text-align: center;
}

.player-action-view-fold span {
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  display: block;
  color: #ffffff;
}

.player-action-view-show {
  background: #373940;
  border: 2px solid #83bc9a;
  border-radius: 2px;
  padding: 10px 8px;
  margin-top: 7px;
  text-align: center;
}

.player-action-view-show span {
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  display: block;
  color: #ffffff;
}

.player-action-view-check {
  background: #373940;
  border: 2px solid #5b72e2;
  border-radius: 2px;
  padding: 10px 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-action-view-check.recent-player {
  background: #5b72e2;
}

.player-action-view-check span {
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  display: block;
  color: #ffffff;
}

.player-action-winner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ddab34;
  box-shadow: 0px 0px 30px rgba(186, 143, 42, 0.5);
  border: 2px solid #ddab34;
  border-radius: 2px;
  padding: 10px 8px;
  text-align: center;
}

.player-action-winner span {
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  display: block;
  color: #ffffff;
  font-family: "DDCHardware" !important;
}

.player-action-winner p {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.player-game-right-view .player-box-grid .player-box-view {
  order: 2;
}

.player-game-right-view .player-box-grid .player-box-action-grid {
  order: 1;
}

.player-game-right-view .player-box-grid .player-box-user span {
  order: 2;
}

.player-game-right-view .player-box-grid .player-box-user h4 {
  order: 1;
}

.player-game-right-view .player-box-grid .player-dropdown {
  left: auto;
  top: 11px;
  right: 11px;
  align-items: center;
  flex-direction: row-reverse;
}

/*.player-game-right-view .player-box-grid .main-dealer-symbol {
  left: 60px;
  right: auto;
}*/
.player-game-right-view .player-box-grid .company-logo-grid {
  left: 0;
  right: auto;
  flex-direction: row-reverse;
}

.player-game-right-view .active-player .player-box-grid .company-logo-grid {
  left: 0px;
}

.player-game-right-view .player-action-view-bet {
  justify-content: flex-start;
}

.player-camera.view-2 .community-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 269px;
  align-items: center;
  background: #373940;
  border-radius: 16px;
  padding: 30px;
}

.player-camera.view-2 .community-cards span {
  width: 18%;
  margin: 0px 1%;
}

.player-camera.view-2 .community-cards span img {
  width: 100%;
  height: auto;
}

.winner-player {
  border: 4px solid #ddab34;
}

.winner-player .player-box-action-grid {
  border-top: 4px solid #ddab34;
}

.player-action-view-check .player-action-view-text {
  padding-bottom: 0;
  font-size: 44px;
  line-height: 50px;
}

/**************************** Empty Player Grid ***************************/

.player-empty-box {
  background: #454851;
  border-radius: 6px;
  height: 194px;
  display: none;
}

/**************************** Player Invite ***************************/

.player-invite-box {
  background: #454851;
  border-radius: 6px;
  height: 194px;
  display: none;
}

/**************************** Dealer Camera ***************************/

.player-camera-box {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  border: 4px solid #31343e;
  display: flex;
}

.player-camera-box img {
  width: 100%;
}

.player-camera.view-1 .player-camera-box img {
  height: 679px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 4px;
}

.player-camera.view-1 .player-camera-box video {
  /*height: 460px !important;*/
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 4px;
}

/*.player-camera.view-1.dealer-camera-view .player-camera-box video {
  height: 460px !important;
}*/

.player-camera.view-1.dealer-camera-view .player-camera-box img {
  height: 306px !important;
}

.player-camera.view-2 .player-camera-box img {
  height: 269px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 16px;
}

.player-camera.view-2 .player-camera-box video {
  width: 100%;
  height: 269px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 16px;
}

.player-camera-box .btn,
.player-camera-box .btn:focus,
.player-camera-box .btn:hover {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #4b605c;
  border: none;
  max-width: 160px;
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height, or 130% */
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "DDCHardware" !important;
}

.player-camera {
  margin-bottom: 12px;
  position: relative;
}

.player-game-not-started {
  position: absolute;
  border-radius: 16px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .player-game-not-started:before {
    content: "";
    background: rgba(35, 38, 46, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
} */

/* .layer-game-not-started-content {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 16px;
    transform: translate(-50%, -50%);
    z-index: 9;
    width: 100%;
    text-align: center;
} */

.layer-game-not-started-content {
  position: absolute;
  left: -15px;
  border-radius: 16px;
  z-index: 9;
  text-align: center;
  bottom: -15px;
}
.layer-game-not-started-content.pause-animation {
  left: 15px;
  bottom: 15px;
}

.layer-game-not-started-content > div {
  width: 120px !important;
  height: 120px !important;
}

.layer-game-not-started-content.pause-animation > div {
  width: 70px !important;
  height: 70px !important;
}

.layer-game-not-started-content h4 {
  margin: 0;
  width: 100%;
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  padding-bottom: 10px;
}

.layer-game-not-started-content p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

/**************************** PLayer Game Action ***************************/

.player-game-action {
  background: #373940;
  box-shadow: 0px -10px 20px rgba(35, 38, 46, 0.2);
  border-radius: 4px 4px 0px 0px;
  padding: 12px;
  position: absolute;
  width: 100%;
  bottom: 0;
}
/*.player-game-action.action-timer-added {
  padding-bottom: 0;
}*/

.player-game-action-content .btn {
  position: relative;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  max-width: 160px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 50px;
  font-family: "DDCHardware" !important;
  box-shadow: none;
  transition: all 0.3s;
}

.player-game-action-content .btn::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 35px;
  background-color: rgb(51 51 51 / 30%);
  top: 50%;
  left: 8px;
  transform: translate(0, -50%);
  border-radius: 2px;
}

.player-game-action-content {
  position: relative;
  display: flex;
  gap: 8px;
  align-items: flex-end;
  background: #373940;
  border-radius: 4px;
}

.player-game-action-content .player-raise-btn.btn {
  background: #57b661;
  border-radius: 0;
  border: none;
  height: 48px;
}

.player-game-action-content .player-raise-btn.btn:hover,
.player-raise-btn.btn-check:checked + .player-raise-btn.btn,
.player-raise-btn.btn.active,
.player-raise-btn.btn.show,
.player-raise-btn.btn:first-child:active,
:not(.player-raise-btn.btn-check) + .player-raise-btn.btn:active {
  background: #83bc9a;
  border-color: #83bc9a;
  box-shadow: none;
}
.player-game-action-content .player-raise-btn:focus,
.player-raise-btn.btn:focus-visible {
  background: #57b661;
  border-color: #57b661;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.player-game-action-content .player-call-btn.btn {
  background: #ddab34;
  border-radius: 6px;
  border-color: #ddab34;
}

.player-game-action-content .player-call-btn.btn:hover,
.player-call-btn.btn-check:checked + .player-call-btn.btn,
.player-call-btn.btn.active,
.player-call-btn.btn.show,
.player-call-btn.btn:first-child:active,
:not(.player-call-btn.btn-check) + .player-call-btn.btn:active {
  background: #af935d;
  border-color: #af935d;
}

.player-game-action-content .player-call-btn:focus,
.player-call-btn.btn:focus-visible {
  background: #ddab34;
  border-color: #ddab34;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.player-game-action-content .player-check-btn.btn {
  background: #5b72e2;
  border-radius: 6px;
  border-color: #5b72e2;
}
.player-game-action-content .player-check-btn.btn:hover,
.player-check-btn.btn-check:checked + .player-check-btn.btn,
.player-check-btn.btn.active,
.player-check-btn.btn.show,
.player-check-btn.btn:first-child:active,
:not(.player-check-btn.btn-check) + .player-check-btn.btn:active {
  background: #5167d2;
  border-color: #5167d2;
}
.player-game-action-content .player-check-btn:focus,
.player-check-btn.btn:focus-visible {
  background: #5b72e2;
  border-color: #5b72e2;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}
.player-game-action-content .player-confirm-btn.btn {
  background: #c7ad7a;
  border-radius: 6px;
  border-color: #c7ad7a;
}

.player-game-action-content .player-confirm-btn.btn:hover,
.player-confirm-btn.btn-check:checked + .player-confirm-btn.btn,
.player-confirm-btn.btn.active,
.player-confirm-btn.btn.show,
.player-confirm-btn.btn:first-child:active,
:not(.player-confirm-btn.btn-check) + .player-confirm-btn.btn:active {
  background: #af935d;
  border-color: #af935d;
}
.player-game-action-content .player-confirm-btn.btn:focus,
.player-confirm-btn.btn:focus-visible {
  background: #c7ad7a;
  border-color: #c7ad7a;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.player-game-action-content .player-fold-btn.btn {
  background: #4c505e;
  border-radius: 6px;
  border-color: #4c505e;
}

.player-game-action-content .player-fold-btn.btn:hover,
.player-fold-btn.btn-check:checked + .player-fold-btn.btn,
.player-fold-btn.btn.active,
.player-fold-btn.btn.show,
.player-fold-btn.btn:first-child:active,
:not(.player-fold-btn.btn-check) + .player-fold-btn.btn:active {
  background: #454851;
  border-color: #454851;
}

.player-game-action-content .player-fold-btn.btn:focus,
.player-fold-btn.btn:focus-visible {
  background: #4c505e;
  border-color: #4c505e;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.player-game-action-content .player-tip-btn.btn,
.player-game-action-content .player-tip-btn.btn:hover,
.player-game-action-content .player-tip-btn.btn:focus,
.player-tip-btn.btn:focus-visible,
.player-tip-btn.btn-check:checked + .player-tip-btn.btn,
.player-tip-btn.btn.active,
.player-tip-btn.btn.show,
.player-tip-btn.btn:first-child:active,
:not(.player-tip-btn.btn-check) + .player-tip-btn.btn:active {
  background: transparent;
  border-radius: 6px;
  border-color: #c7ad7a;
  color: #c7ad7a;
}

.player-game-action-content .player-allin-btn.btn {
  background: #d93355;
  border-radius: 6px;
  border-color: #d93355;
}

.player-game-action-content .player-allin-btn.btn:hover,
.player-allin-btn.btn:focus,
.player-allin-btn.btn:focus-visible,
.player-allin-btn.btn-check:checked + .player-allin-btn.btn,
.player-allin-btn.btn.active,
.player-allin-btn.btn.show,
.player-allin-btn.btn:first-child:active,
:not(.player-allin-btn.btn-check) + .player-allin-btn.btn:active {
  background: #d6002b;
  border-color: #d6002b;
}
.player-game-action-content .player-allin-btn.btn:focus,
.player-allin-btn.btn:focus-visible {
  background: #d93355;
  border-color: #d93355;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}
/**************************** Dealer Game Action ***************************/

.dealer-game-action form {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.dealer-game-action form .form-control,
.dealer-game-action form .form-control:focus {
  height: 40px;
  background: #454851;
  border-radius: 6px !important;
  border-color: #454851;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-transform: uppercase;

  /* Grayscale / Gray Lighter */
  color: #a5a5a5;
  font-family: "DDCHardware" !important;
  box-shadow: none;
  width: 100%;
  transition: all 0.3s;
}

.dealer-game-action form .input-group .btn,
.dealer-game-action form .input-group .btn:hover,
.dealer-game-action form .input-group .btn:focus {
  background: #c7ad7a;
  border: 1px solid #c7ad7a;
  border-radius: 6px !important;
  width: 40px !important;
  height: 40px !important;
}
.dealer-game-action form .btn.player-pause-btn img {
  margin-left: 10px;
}

.dealer-game-action-form {
  display: flex;
  width: 68%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dealer-game-action-form .input-group {
  justify-content: space-between;
}

.dealer-game-action-form .form-group {
  width: 48%;
}

.dealer-game-action form .input-group .form-control,
.dealer-game-action
  form
  .input-group
  .form-control:hover
  .dealer-game-action
  form
  .input-group
  .form-control:focus {
  width: calc(100% - 50px);
  flex: inherit;
}

.dealer-game-action form .player-pause-btn,
.dealer-game-action form .player-pause-btn:hover,
.dealer-game-action form .player-pause-btn:focus {
  height: 54px !important;
  background: #c7ad7a;
  border-color: #c7ad7a;
  border-radius: 6px;
  width: 30%;
  padding: 10px;
}

.dealer-game-action form .form-control::-webkit-input-placeholder {
  color: #a5a5a5;
}

.dealer-game-action form .form-control::-moz-placeholder {
  color: #a5a5a5;
}

.dealer-game-action form .form-control:-ms-input-placeholder {
  color: #a5a5a5;
}

.dealer-game-action form .form-control::-ms-input-placeholder {
  color: #a5a5a5;
}

.dealer-game-action form .form-control::placeholder {
  color: #a5a5a5;
}

.dealer-game-action form .form-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: "DDCHardware" !important;
  margin-bottom: 10px;
  transition: all 0.3s;
}

.dealer-game-action form .btn,
.dealer-game-action form .btn:hover {
  height: 40px;
}

.dealer-game-action form .btn img {
  margin: 0;
}

.dealer-game-action {
  width: 100%;
}

.dealer-game-action-form input.form-control:disabled {
  opacity: 0.3;
}

.dealer-game-action form .disabled-label.form-label {
  opacity: 0.3;
}

.dealer-game-action form .input-group .btn.check-effect,
.dealer-game-action form .input-group .btn.check-effect:hover,
.dealer-game-action form .input-group .btn.check-effect:focus {
  background: #83bc9a;
  border: 1px solid #83bc9a;
}

/**************************** Raise Widget *****************************/

.player-placeholder-btns .placeholder-btn {
  width: 100%;
  height: 50px;
  background: #454851;
  display: block;
  border-radius: 6px;
}

.player-placeholder-btns {
  display: flex;
  width: calc(100% - 0px);
  justify-content: space-between;
  gap: 8px;
}

.raise-widget {
  padding-bottom: 20px;
}

.raise-widget-content form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.raise-slider {
  max-width: 220px;
  width: 100%;
}

.raise-quick-bets {
  width: calc(100% - 240px);
}

.raise-slider .form-control,
.raise-slider .form-control:focus {
  background: #454851;
  border-radius: 6px;
  border-color: #454851;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "DDCHardware" !important;
  height: 38px;
  box-shadow: none;
  padding: 8px 12px;
  margin-bottom: 20px;
}

.raise-quick-bets .btn,
.raise-quick-bets .btn:hover,
.raise-quick-bets .btn:focus {
  border: 1px solid #c7ad7a;
  border-radius: 6px;
  background: transparent;
  max-width: 67px;
  width: 100%;
  margin-right: 5px;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  font-family: "DDCHardware" !important;
  box-shadow: none;
  transition: all 0.3s;
}

.raise-quick-bets .btn:hover,
.raise-quick-bets .btn-check:checked + .raise-quick-bets .btn,
.raise-quick-bets .btn.active,
.raise-quick-bets .btn.show,
.raise-quick-bets .btn:first-child:active,
:not(.raise-quick-bets .btn-check) + .raise-quick-bets .btn:active {
  color: #af935d;
  border-color: #af935d;
  box-shadow: none;
  background: transparent;
}

.raise-quick-bets .btn:focus,
.raise-quick-bets .btn:focus-visible {
  background: transparent;
  box-shadow: 0 0 0 2px #c7ad7a;
  color: #fff;
}

.raise-quick-bets .btn.all-in-btn,
.raise-quick-bets .btn.all-in-btn:hover,
.raise-quick-bets .btn.all-in-btn:focus {
  color: #c7ad7a;
}

.raise-widget .change-stack {
  padding: 0;
}

.raise-widget .change-stack .input-range {
  margin: 0;
}

.disable-btn {
  opacity: 0.3;
  pointer-events: none;
}

.raise-widget .input-range__label-container {
  display: none;
}

/**************************** Profile Viewer *****************************/

.profile-viewer {
  background: #31343e;
  border-radius: 6px;
  padding: 20px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  max-width: 378px;
  box-shadow: 10px 10px 30px #1b1d238c;
}

.profile-viewer.chat-profile-view {
  max-width: 378px;
  top: unset;
  left: unset;
  right: 0;
}

.chat-profile-view .preview-info h4 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.chat-profile-view .preview-info h4::after {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: #83bc9a;
  top: 8px;
  right: -16px;
  border-radius: 100px;
}

.profile-viewer span.closeprofile {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}

.player-game-right-view .profile-viewer {
  left: auto;
  right: 0;
}

select#video-devices,
select#video-devices:focus,
select#video-devices:hover {
  position: absolute;
  margin-left: 60px;
  background: #373940;
  border: 1px solid #31343e;
  color: #a9a9a9;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  height: 50px;
  width: 208px;
  padding: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  left: 0;
  top: 15px;
  font-family: "DDCHardware" !important;
}

/**************************** Animation *****************************/

.card-animate-fold {
  position: absolute;
  top: -99px;
  bottom: 0;
  left: -16px;
  right: 0;
  width: 250px;
  height: 250px;
  display: none;
}

.card-animate-cardview {
  position: absolute;
  top: -99px;
  bottom: 0;
  left: -16px;
  right: 0;
  width: 250px;
  height: 250px;
  display: none;
}

.card-animate-firstcard {
  position: absolute;
  top: -99px;
  bottom: 0;
  left: -16px;
  right: 0;
  width: 250px;
  height: 250px;
  display: none;
}

.card-animate-secondcard {
  position: absolute;
  top: -99px;
  bottom: 0;
  left: -16px;
  right: 0;
  width: 250px;
  height: 250px;
  display: none;
}

.firstCard .card-animate-firstcard {
  display: block;
}

.secondCard .card-animate-secondcard {
  display: block;
}

.foldCard .card-animate-fold {
  display: block;
}

.showCard .card-animate-cardview {
  display: block;
}

/**********************************************************************************/
/*************************************** Player View by Length*****************************/
/**********************************************************************************/

/*************** Player 1 **************/

.player-length-1 .player-box {
  height: 644px;
}

.player-length-1 .player-box-list {
  height: 100%;
}

.player-length-1 .player-box-grid {
  height: 100%;
  flex-wrap: wrap;
}

.player-length-1 .player-box-view {
  width: 100%;
  height: 552px;
}

.player-length-1 .player-box-action-grid {
  height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  max-width: 315px;
}

.player-length-1 .player-box-iframe {
  height: 100%;
}

/*.player-length-1 .player-box-iframe img {
  height: 100%;
}*/

.player-length-1 .player-box-iframe video {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.player-game-right-view.player-length-1 .player-box-grid .player-box-view {
  order: 1;
}

.player-game-right-view.player-length-1 .player-box-card {
  order: 2;
  transform: scale(66%) translateX(28%);
}

.player-length-1 .player-box-user h4 {
  font-size: 26px;
  line-height: 32px;
}

.player-length-1 .player-action-view {
  margin: 0;
  padding: 10px 8px;
  height: 68px;
}

.player-game-left-view.player-length-1 .player-box-action {
  margin-left: -49px;
}

.player-game-right-view.player-length-1 .player-box-action {
  margin-right: -49px;
}

.player-length-1 .player-action-view-check,
.player-length-1 .player-action-view-fold,
.player-length-1 .player-action-view-show {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.player-length-1 .player-box-card {
  max-width: 158px;
  width: 100%;
  transform: scale(66%) translateX(-28%);
}

.player-length-1 .player-box-action {
  width: calc(100% - 60px);
}

.player-length-1 .player-action-view-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  padding: 0;
}

.player-length-1 .player-action-view h4 {
  font-size: 26px;
  line-height: 32px;
}

.player-length-1 .player-action-view-bet span img {
  width: 24px;
}

.player-length-1 .player-action-view-bet span {
  font-size: 22px;
}

.player-length-1 .player-action-empty-field {
  border: 2px solid #454851;
  border-radius: 2px;
  width: 100%;
  min-height: 68px;
}

/*************** Player 2 **************/

.player-length-2 .player-box {
  height: 320px;
}

.player-length-2 .player-box-list {
  height: 100%;
}
.player-length-2 .player-box-grid {
  height: 100%;
  flex-wrap: wrap;
}

.player-length-2 .player-box-view {
  width: 100%;
  height: 228px;
}

.player-length-2 .player-box-action-grid {
  max-width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.player-length-2 .player-box-iframe {
  height: 100%;
}

/*.player-length-2 .player-box-iframe img {
  height: 100%;
}*/

.player-length-2 .player-box-iframe video {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.player-game-right-view.player-length-2 .player-box-grid .player-box-view {
  order: 1;
}

.player-game-right-view.player-length-2 .player-box-card {
  order: 2;
}

.player-length-2 .player-box-user h4 {
  font-size: 26px;
  line-height: 32px;
}

.player-length-2 .player-action-view {
  margin: 0;
  padding: 10px 8px;
  height: 68px;
}

.player-length-2 .player-action-view-check,
.player-length-2 .player-action-view-fold,
.player-length-2 .player-action-view-show {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.player-length-2 .player-action-winner {
  padding: 10px 8px;
  height: 68px;
}

.player-length-2 .player-box-card {
  max-width: 130px;
  width: 100%;
  transform: scale(80%);
}

.player-length-2 .player-box-action {
  width: calc(100% - 130px);
}

.player-length-2 .player-box-card span img {
  width: 59px;
  height: 81px;
}

.player-length-2 .player-action-view-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  padding: 0;
}

.player-action-view-check .player-length-2 .player-action-view-text {
  font-size: 26px;
  line-height: 32px;
}

.player-length-2 .player-action-view h4 {
  font-size: 26px;
  line-height: 32px;
}

.player-length-2 .player-action-view-bet span img {
  width: 24px;
}

.player-length-2 .player-action-view-bet span {
  font-size: 16px;
}

.player-length-2 .card-animate-firstcard {
  top: -78px;
  left: -13px;
  width: 200px;
  height: 200px;
}

.player-length-2 .card-animate-secondcard {
  top: -78px;
  left: -13px;
  width: 200px;
  height: 200px;
}

.player-length-2 .card-animate-fold {
  top: -78px;
  left: -13px;
  width: 200px;
  height: 200px;
}

.player-length-2 .card-animate-cardview {
  top: -78px;
  left: -13px;
  width: 200px;
  height: 200px;
}

.player-length-2 .player-action-empty-field {
  border: 2px solid #454851;
  border-radius: 2px;
  width: 100%;
  min-height: 68px;
}

/*************** Player 3 **************/

.player-length-3 .player-box {
  height: 212px;
}

.player-length-3 .player-box-list {
  height: 100%;
}

.player-length-3 .player-box-grid {
  height: 100%;
}

.player-length-3 .player-box-view {
  height: 100%;
  width: 192px;
}

.player-length-3 .player-box-action-grid {
  height: 100%;
  display: flex;
  align-items: flex-end;
  width: 115px;
  max-width: inherit;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
  border-top: 0px solid transparent;
  border-left: 4px solid transparent;
}

.player-length-3 .winner-player .player-box-action-grid {
  border-left: 2px solid #ddab34;
}

.player-game-right-view.player-length-3 .player-box-action-grid {
  border-right: 4px solid transparent;
  border-left: 0px solid transparent;
}

.player-game-right-view.player-length-3 .winner-player .player-box-action-grid {
  border-right: 2px solid #ddab34;
}

.player-length-3 .player-box-iframe {
  height: 100%;
}

/*.player-length-3 .player-box-iframe img {
  height: 100%;
}*/

.player-length-3 .player-box-iframe video {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.player-length-3 .player-action-view-check {
  padding: 8px;
  margin-top: 0px;
  height: 62px;
}

.player-length-3 .player-action-view-check span {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.player-length-3 .player-action-view-fold span,
.player-length-3 .player-action-view-show span {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.player-length-3 .player-action-winner span {
  font-size: 20px;
  line-height: 26px;
}

.player-length-3 .player-action-winner p {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.player-length-3 .player-action-winner {
  padding: 10px 8px;
}

.player-length-3 .player-action-view-check .player-action-view-text {
  font-size: 20px;
  line-height: 26px;
}

.player-length-3 .player-box-card {
  max-width: 150px;
  width: 100%;
  transform: scale(74%) translate(-18%, 19%);
}
.player-length-3 .player-action-view {
  margin-top: 0;
  padding: 3px 8px;
  min-height: 62px;
}

.player-length-3 .player-action-view-text {
  font-size: 14px;
  line-height: 18px;
  padding: 0;
}

.player-length-3 .player-action-view h4 {
  font-size: 20px;
  line-height: 26px;
}

.player-length-3 .player-action-view-fold,
.player-length-3 .player-action-view-show {
  margin-top: 0;
  min-height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-length-3 .player-box-action {
  max-width: 150px;
  width: 100%;
}

.player-length-3 .player-box-card span img {
  width: 69.75px;
  height: 91px;
  border: 2px solid #a6a7aa;
  outline: none;
}

.player-length-3 .card-animate-firstcard {
  top: -88px;
  left: -14px;
  width: 225px;
  height: 225px;
}

.player-length-3 .card-animate-secondcard {
  top: -88px;
  left: -14px;
  width: 225px;
  height: 225px;
}

.player-length-3 .card-animate-fold {
  top: -88px;
  left: -14px;
  width: 225px;
  height: 225px;
}

.player-length-3 .card-animate-cardview {
  top: -88px;
  left: -14px;
  width: 225px;
  height: 225px;
}

.player-length-3 .player-action-empty-field {
  border: 2px solid #454851;
  border-radius: 2px;
  width: 100%;
  min-height: 62px;
}

/*************** Player 4 **************/

.player-length-4 .player-box {
  height: 158px;
}

.player-length-4 .player-box-list {
  height: 100%;
}

.player-length-4 .player-box-grid {
  height: 100%;
}

.player-length-4 .player-box-view {
  height: 100%;
  width: 192px;
}

.player-length-4 .player-box-action-grid {
  height: 100%;
  display: flex;
  align-items: center;
  width: 115px;
  max-width: inherit;
  justify-content: flex-end;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
  border-top: 0px solid transparent;
  border-left: 4px solid transparent;
}

.player-length-4 .winner-player .player-box-action-grid {
  border-left: 4px solid #ddab34;
}

.player-game-right-view.player-length-4 .player-box-action-grid {
  border-right: 4px solid transparent;
  border-left: 0px solid transparent;
}

.player-game-right-view.player-length-4 .winner-player .player-box-action-grid {
  border-right: 4px solid #ddab34;
}

.player-length-4 .player-box-iframe {
  height: 100%;
}

/*.player-length-4 .player-box-iframe img {
  height: 100%;
}*/

.player-length-4 .player-box-iframe video {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.player-length-4 .player-action-view-text {
  font-size: 14px;
  line-height: 18px;
}

.player-length-4 .player-action-view h4 {
  font-size: 20px;
  line-height: 26px;
}

.player-length-4 .player-action-view-bet span {
  font-size: 16px;
  line-height: 20px;
}

.player-length-4 .player-action-winner span {
  font-size: 20px;
  line-height: 26px;
}

.player-length-4 .player-action-winner p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.player-length-4 .player-action-winner {
  padding: 4px;
  min-height: 69px;
}

.player-length-4 .player-action-view-fold span,
.player-length-4 .player-action-view-show span {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}

.player-length-4 .player-action-view-check .player-action-view-text {
  font-size: 20px;
  line-height: 20px;
}

.player-length-4 .player-action-view-fold,
.player-length-4 .player-action-view-show {
  padding: 4px;
  margin-top: 0;
  min-height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.player-length-4 .player-action-view-check {
  min-height: 69px;
}
.player-length-4 .player-box-card {
  max-width: 150px;
  width: 100%;
  transform: scale(72%) translate(-17%, 19%);
}
.player-length-4 .player-action-view {
  margin-top: 0;
  padding: 2.5px 4px;
  min-height: 69px;
}

.player-length-4 .player-box-action {
  max-width: 150px;
  width: 100%;
}

.player-length-4 .player-box-card span img {
  width: 69.94px;
  height: 89.96px;
  border: 2px solid #a6a7aa;
  outline: none;
}

.player-length-4 .card-animate-firstcard {
  top: -87px;
  left: -13px;
  width: 220px;
  height: 220px;
}

.player-length-4 .card-animate-secondcard {
  top: -87px;
  left: -13px;
  width: 220px;
  height: 220px;
}

.player-length-4 .card-animate-fold {
  top: -87px;
  left: -13px;
  width: 220px;
  height: 220px;
}

.player-length-4 .card-animate-cardview {
  top: -87px;
  left: -13px;
  width: 220px;
  height: 220px;
}

.placeholder-btn-action {
  width: 32%;
  height: 50px;
  background: #454851;
  display: block;
  border-radius: 6px;
}
.player-length-4 .player-action-empty-field {
  border: 2px solid #454851;
  border-radius: 2px;
  width: 100%;
  min-height: 69px;
}

.end-private-call {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(35, 38, 46, 0.7);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.end-private-call .btn,
.end-private-call .btn:first-child:active {
  background: #c7ad7a;
  border-radius: 6px;
  max-width: 170px;
  width: 100%;
  border-color: #c7ad7a;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  height: 50px;
  font-family: "DDCHardware" !important;
}

.end-private-call .btn:hover {
  background: #af935d;
  border-color: #af935d;
}

.end-private-call .btn:focus,
.end-private-call .btn:focus-visible {
  background: #c7ad7a;
  border-color: #57626680;
  box-shadow: 0px 0px 0px 2px #57626680;
}

.player-box-iframe:hover .end-private-call {
  opacity: 1;
  visibility: visible;
}

.player-game-view-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.player-camera.view-1 {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.main-player-camoff {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #454851;
}

.main-player-camoff img {
  width: 64px;
  height: 64px;
  border: 3px solid #454851;
  border-radius: 100%;
}

.player-game-action-grid {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  justify-content: flex-end;
  min-height: 94px;
}

/******************** Progress bar ********************/
.player-game-action .progress {
  background-color: #31343e;
  height: 6px;
  border-radius: unset;
  margin: 0 -12px;
  margin-bottom: -12px;
  position: absolute;
  width: 520px;
  bottom: 0;
}
.player-game-action .progress-bar {
  background-color: #af935d;
}

.player-box-card.self-fold span {
  opacity: 1;
  visibility: visible;
}

.player-box-card.self-fold span img {
  visibility: visible;
  opacity: 0.2;
}

.opponent-fold .card-animate-cardview {
  display: none !important;
}

/**************************************** New Action Design *****************************************/

.bet-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
  background: #454851;
  border-radius: 6px 6px 0 0;
}

.bet-btn-wrapper {
  max-width: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #57b661;
  border-radius: 6px;
}

.bet-btn-wrapper input.form-control {
  width: calc(100% - 56px);
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "DDCHardware" !important;
  text-transform: uppercase;
  color: #ffffff;
  background: transparent;
  border: none;
  outline: 0;
  padding: 0;
}

.bet-btn-wrapper input.form-control:focus {
  box-shadow: none;
}

.increment-icon-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #373940;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s;
}

.increment-icon-btn:hover {
  background: #4c505e;
}

.player-range-action-wrapper {
  max-width: 328px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 0;
  top: 0;
}

.player-range-input {
  max-width: 160px;
  width: 100%;
}

.player-pot-input {
  max-width: 160px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 6px;
}

.pot-input-btn.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  width: 100%;
  height: 34px;
  background: #454851;
  border-color: #454851;
  border-radius: 6px;
  font-family: "DDCHardware" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s;
}

.pot-input-btn.btn::before {
  display: none;
}

.pot-input-btn.btn:hover,
.pot-input-btn.btn:active,
.pot-input-btn.btn:first-child:active,
.pot-input-btn.btn-check:checked + .pot-input-btn.btn,
.pot-input-btn.btn.active,
.pot-input-btn.btn.show,
:not(.pot-input-btn.btn-check) + .pot-input-btn.btn:active {
  background: #4c505e;
  border-color: #4c505e;
}
.pot-input-btn.btn:focus,
.pot-input-btn.btn:focus-visible {
  background: #454851;
  border-color: #454851;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.pot-input-btn.btn:disabled {
  background: #454851;
  border-color: #454851;
  color: #808595;
}

.player-range-input .input-range {
  height: 24px;
}

.player-range-input .input-range__track {
  height: 24px;
  background: #454851;
  border-radius: 50px;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
}

.player-range-input .input-range__track--active {
  background: #57b661;
}

.player-range-input .input-range__slider {
  width: 28px;
  height: 28px;
  background: #fff;
  border-radius: 46px;
  border-color: #fff;
  margin-top: -26px;
  margin-left: -14px;
  z-index: 9;
}

/*********************** Dealer name *********************/

.dealer-name-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  box-shadow: 0px 10px 60px #1b1d23;
  background: radial-gradient(
      341.25% 341.25% at 54.33% -118.75%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(87, 98, 102, 0.5);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
}

.dealer-name-wrapper.current-round-wrapper {
  display: none;
  opacity: 0;
  visibility: hidden;
  top: auto;
  bottom: 10px;
  border-radius: 6px;
  width: 117px;
  height: 36px;
}

.table-count-wrapper {
  right: unset;
  left: 10px;
}

.dealer-name-wrapper span {
  color: #ffffff;
  letter-spacing: 0.03rem;
  font-family: "DDCHardware";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

/**********************************************************************************/
/*********************************** Automated Player *****************************/
/**********************************************************************************/

.tournament-game-view .player-bid-amount {
  width: unset;
  height: unset;
  padding: 4px 0;
}
/******************* Header ******************/

.tournament-game-view .automated-game-head {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  -moz-column-gap: 58px;
  column-gap: 58px;
  margin-bottom: 30px;
  position: relative;
}

.tournament-game-view .game-cp-wrapper {
  max-width: 720px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../../assets/images/playerview/cpTagBg.svg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.tournament-game-view .game-cp-wrapper h5 {
  margin: 0;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: #fff;
}

.player-bid-button .btn-check:checked + .player-bid-button .btn,
.player-bid-button .btn.active,
.player-bid-button .btn.show,
.player-bid-button .btn:first-child:active,
:not(.player-bid-button .btn-check) + .player-bid-button .btn:active {
  color: #c7ad7a;
  background: transparent;
  border-color: #c7ad7a;
}

.icon-btn.btn,
.icon-btn.btn:disabled {
  background: #c7ad7a;
  border-radius: 6px;
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.icon-btn.btn:hover,
.icon-btn.btn:focus,
.icon-btn.btn:focus:not(:focus-visible),
.icon-btn.btn:focus-visible,
.icon-btn.btn-check:checked + .icon-btn.btn,
.icon-btn.btn.active,
.icon-btn.btn.show,
.icon-btn.btn:first-child:active,
:not(.icon-btn.btn-check) + .icon-btn.btn:active {
  background: #9d896d;
  border: none;
  outline: 0;
}

.icon-btn.btn:focus,
.icon-btn.btn:focus:not(:focus-visible),
.icon-btn.btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.icon-btn.enabled.btn {
  background: #fff;
}

.icon-btn.enabled.btn:hover,
.icon-btn.enabled.btn:focus,
.icon-btn.enabled.btn:focus:not(:focus-visible),
.icon-btn.enabled.btn:focus-visible,
.icon-btn.enabled.btn-check:checked + .icon-btn.enabled.btn,
.icon-btn.enabled.btn.active,
.icon-btn.enabled.btn.show,
.icon-btn.enabled.btn:first-child:active,
:not(.icon-btn.enabled.btn-check) + .icon-btn.enabled.btn:active {
  background: #a0aabb;
  border: none;
  outline: 0;
}

.icon-btn.enabled.btn:focus,
.icon-btn.enabled.btn:focus:not(:focus-visible),
.icon-btn.enabled.btn:focus-visible {
  background: #fff;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.tournament-game-view .automated-head-btns {
  display: flex;
  align-items: center;
  gap: 16px;
}

.tournament-game-view .automated-head-btns-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

/******************** Player Camera ********************/

.tournament-game-view .player-camera.view-1 .player-camera-box img,
.tournament-game-view .player-camera.view-1 .player-camera-box video {
  height: 306px;
}

.tournament-game-view
  .player-camera.view-1
  .community-card-cameraTow-view
  video {
  width: 520px !important;
  height: 292.5px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%) scale(1);
}

.player-camera-box.community-card-cameraTow-view {
  height: 152px;
}

.tournament-game-view .player-camera {
  margin-bottom: unset;
}

.single-dealer-cam .player-camera.view-1 .player-camera-box video {
  height: 582px !important;
}
/*********************** Player Bid *********************/

.tournament-game-view .player-bid-box {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 4px;
}

.tournament-game-view .player-bid-grid {
  max-width: unset;
}

.tournament-game-view .player-bid,
.player-bid.player-pot-count {
  padding: 4px 12px;
}

.tournament-game-view .player-bid-info h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}

.tournament-game-view .player-bid {
  align-items: flex-end;
}

.tournament-game-view .player-bid-icon {
  padding-bottom: 4px;
}

/******************* blind timer dropdown *****************/

.tournament-game-view .player-bidtimer-drop-wrapper {
  position: absolute;
  top: -244px;
  width: 100%;
  height: 236px;
  left: 0;
  display: flex;
  align-items: flex-end;
  background: transparent;
  border-radius: 6px;
  z-index: 99;
  transition: all 0.3s;
  visibility: hidden;
  transform: translateY(20px);
  opacity: 0;
}

.tournament-game-view .player-bidtimer-drop-wrapper.show {
  visibility: visible;
  transform: translateY(0px);
  opacity: 1;
}

.tournament-game-view .player-blind-timer-dropdown {
  position: unset;
  top: 75px;
  width: 100%;
  left: 0;
  background: #373940;
  border-radius: 6px;
  padding: 16px 20px;
  z-index: 99;
  transition: all 0.3s;
  visibility: unset;
  transform: unset;
  opacity: unset;
}

.tournament-game-view .player-blind-timer-dropdown.show {
  visibility: unset;
  transform: unset;
  opacity: unset;
}

/******************* Automated Card *********************/

.tournament-game-view .automated-community-card-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 5px;
  gap: 8px;
  border: 4px solid #31343e;
  border-radius: 6px;
  min-height: 150px;
}

.automated-community-card-wrapper span:nth-child(n + 6) {
  display: none;
}

.tournament-game-view .automated-community-card-wrapper span img {
  width: 94px;
  height: 128px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 6px;
}

/********************** player box *******************/

.tournament-game-view .player-length-2 .player-box-user span svg,
.tournament-game-view .player-box-user span svg {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -10px;
}

/********************** Tentative action btns ************************/

.tournament-game-view .tentative-action-btn.form-check {
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
}
.tentative-box.form-label {
  padding: 8px;
  max-width: 158.5px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  margin: 0;
  cursor: pointer;
}

.tentative-box.form-label.tentative-fold,
.tentative-box.form-label.tentative-check-fold {
  background: #4c505e;
  transition: all 0.3s;
}

.tentative-box.form-label.tentative-check {
  background: #5b72e2;
  transition: all 0.3s;
}

.tentative-box.form-label.tentative-call {
  background: #ddab34;
  transition: all 0.3s;
}

.tentative-box.form-label.tentative-call-any {
  background: #d7905d;
  transition: all 0.3s;
}

.tentative-box.form-label.tentative-all-in {
  background: #d93355;
  transition: all 0.3s;
}

.tentative-box.form-label.tentative-fold:hover,
.tentative-box.form-label.tentative-check-fold:hover {
  background: #454851;
}

.tentative-box.form-label.tentative-check:hover {
  background: #00acee;
}

.tentative-box.form-label.tentative-call:hover {
  background: #af935d;
}

.tentative-box.form-label.tentative-call-any:hover {
  background: #ac734a;
}

.tentative-box.form-label.tentative-all-in:hover {
  background: #d6002b;
}

.tentative-box.form-label.tentative-fold:focus,
.tentative-box.form-label.tentative-check-fold:focus,
.tentative-box.form-label.tentative-check:focus,
.tentative-box.form-label.tentative-call:focus,
.tentative-box.form-label.tentative-call-any:focus,
.tentative-box.form-label.tentative-all-in:focus {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.tournament-game-view .player-game-action-check-content {
  gap: 10px;
  min-height: 94px;
}

.tentative-box input[type="checkbox"] {
  margin: 0;
  box-shadow: none;
  transition: all 0.3s;
  width: 9px;
  height: 35px;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
}

.tentative-box .tentative-fold-btn input[type="checkbox"],
.tentative-box .tentative-check-fold-btn input[type="checkbox"] {
  background-color: #373940;
}

.tentative-box .tentative-check-btn input[type="checkbox"] {
  background-color: #445cd4;
}

.tentative-box .tentative-call-btn input[type="checkbox"] {
  background-color: #cc9028;
}

.tentative-box .tentative-call-any-btn input[type="checkbox"] {
  background-color: #c18153;
}

.tentative-box .tentative-all-in-btn input[type="checkbox"] {
  background-color: #b92745;
}

.tentative-box.form-label.tentative-check:hover
  .tentative-check-btn
  input[type="checkbox"] {
  background-color: #0691c6;
}

.tentative-box.form-label.tentative-call:hover
  .tentative-call-btn
  input[type="checkbox"] {
  background-color: #9a7f4c;
}

.tentative-box.form-label.tentative-call-any:hover
  .tentative-call-any-btn
  input[type="checkbox"] {
  background-color: #9d6034;
}

.tentative-box.form-label.tentative-all-in:hover
  .tentative-all-in-btn
  input[type="checkbox"] {
  background-color: #b92745;
}

.tentative-box input:checked[type="checkbox"] {
  background-image: url(../../../assets/images/checkBar.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 5px 31px;
}

.tournament-game-view .tentative-action-btn .form-check-label {
  font-family: "DDCHardware";
  font-weight: 400;
  font-size: 16px;
  line-height: initial;
  text-transform: uppercase;
  width: calc(100% - 10px);
  justify-content: center;
  cursor: pointer;
}

.dealer-camera-view + .player-bid-amount {
  padding-bottom: 0;
}

/*******************************************************************************************************/
/******************************************* New Main player UI ************************************** */
/*******************************************************************************************************/

.player-view-main .container {
  max-width: 1600px !important;
  padding: 0;
  position: relative;
}

.user-main-game-header .logo {
  display: none;
}

.game-view-banner-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  max-width: 1230px;
  width: 100%;
  background: #31343e;
  border-radius: 20px;
  height: 0px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
}

.game-view-banner-main.show {
  height: 142px;
  opacity: 1;
  visibility: visible;
}

.game-view-banner-main span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a0aabb;
}

.player-game-view-container {
  display: flex;
  justify-content: center;
  background: #23262e;
  border: 2px solid #373940;
  border-radius: 20px;
  width: 100%;
  height: 684px;
  overflow: hidden;
  transition: all 0.3s;
}

.player-game-view-wrapper-top {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 20px;
  justify-content: space-between;
  transition: all 0.3s;
}

.player-game-view-top-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 1230px;
}

.player-game-view-top-right.hide {
  height: 100%;
}

.player-game-view-top-right.rail {
  height: 684px;
}

.color-text {
  color: #c7ad7a !important;
}

.player-view-main-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0) scale(100%);
  transition: all 0.3s;
  /*padding: 20px 0;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 955px;
  gap: 8px;
}
.player-view-main-wrapper .player-game-sidebar,
.player-view-main-wrapper .logo {
  display: none;
}
.game-sidebar-main span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a0aabb;
}

.view-game-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 0px;
  gap: 4px;
  background: #31343e;
  border-radius: 16px;
  cursor: pointer;
}

.view-game-wrapper span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  display: flex;
  gap: 4px;
  color: #fff;
}

.side-setting-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 16px;
  gap: 8px;
  background: #31343e;
  border-radius: 20px;
}

.setting-btn-group {
  width: 100%;
}
.setting-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
  background: #454851;
  border-radius: 16px;
  width: 100%;
  height: 40px;
  border-color: #454851;
  position: relative;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
  transition: all 0.3s;
}

.setting-btn.btn,
.setting-btn.btn:active,
.setting-btn.btn:first-child:active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
  background: #454851;
  border-radius: 16px;
  width: 100%;
  height: 40px;
  border-color: #454851;
  position: relative;
  outline: 0;
  box-shadow: none;
  transition: all 0.3s;
}

.setting-btn.btn:hover,
.setting-btn:hover {
  background: #4c505e;
  border-color: #4c505e;
}

.setting-btn.btn:focus,
.setting-btn.btn:focus-visible {
  background: #31343e;
  border-color: #31343e;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.btn-down-icon {
  transition: all 0.3s;
}

.btn-down-icon.rotate {
  transform: rotate(-180deg);
}

.drop-wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  background: #31343e;
  border: 1px solid #454851;
  box-shadow: 0px 10px 60px #1b1d23;
  border-radius: 16px;
  transform: translateY(0px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 99;
  top: 46px;
  left: 0;
}
.drop-wrapper.show {
  transform: translateY(8px);
  opacity: 1;
  visibility: visible;
}

.show-drop-btn {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 16px;
  gap: 8px;
  justify-content: space-between;
  background: #31343e;
  border-radius: 16px;
  cursor: pointer;
}

.show-drop-btn h5 {
  font-family: "DDCHardware";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #a0aabb;
  margin: 0;
  gap: 8px;
}

.show-drop-btn h5 span {
  font-family: "DDCHardware";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
}

.table-drop-list-wrapper {
  top: 35px;
}

.table-drop-list-wrapper span {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  gap: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 0;
  transition: all 0.3s;
  cursor: pointer;
}
.table-drop-list-wrapper span:hover {
  background: #4c505e;
}

.game-sidebar-main {
  min-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px;
  gap: 8px;
  height: 100%;
}

.stat-btn.btn,
.stat-btn.btn:active,
.stat-btn.btn:first-child:active,
:not(.stat-btn.btn-check) + .stat-btn.btn:active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  height: 42px;
  background: #31343e;
  border-color: #31343e;
  border-radius: 20px;
  font-family: "DDCHardware";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #83bc9a;
  transition: all 0.3s;
}

.stat-btn.btn:hover {
  background: #4c505e;
  border-color: #4c505e;
}

.stat-btn.btn:focus,
.stat-btn.btn:focus-visible {
  background: #31343e;
  border-color: #31343e;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.stat-btn.btn h3 {
  display: block;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  margin: 0;
  color: #fff;
}

.game-stats-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.drop-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  gap: 10px;
  width: 100%;
}

.drop-item .invite-user-action {
  width: 100%;
}

.drop-item .invite-user-action .form-check {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  gap: 10px;
}

.drop-item .invite-user-action .form-check .form-check-label {
  max-width: calc(100% - 30px);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}

.drop-item-divider {
  background: #4c505e;
  height: 1px;
  padding: 0;
  width: 93%;
  margin: 8px auto;
}

.volume-control-wrapper {
  width: 100%;
  padding: 12px;
}

.selectValue {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  width: 46px;
  height: 28px;
  background: #4c505e;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.volume-range-main .volume-input-slider:active + #selector .selectValue {
  opacity: 1;
  visibility: visible;
}

.selectValue::after {
  display: block;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  bottom: -4px;
  color: #4c505e;
}

.slider-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding-bottom: 12px;
}

.slider-title span {
  font-family: "DDCHardware";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #ffffff;
  max-width: unset;
  padding: 0;
}

/*.player-game-view-container .player-game-view-wrapper {
  transform: translate(-13%, -12.8%) scale(74%);
}*/

.slider-inc-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 24px;
  height: 24px;
  background: #454851;
  border-radius: 136px;
  cursor: pointer;
}

.player-game-right-view.player-length-1 .player-action-view {
  /*margin-right: -49px;*/
  margin-left: unset;
}

.table-drop-wrapper {
  position: relative;
}

.player-box-user.mute span {
  padding-right: 32px;
}

/****************************************************************************************************/
/*************************************** Sidebar Tabs Section ***************************************/
/****************************************************************************************************/

.chat-wrapper {
  height: 674px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  isolation: isolate;
  background: #31343e;
  border-radius: 20px;
  overflow: hidden;
}

.tab-card-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 100%;
  background: #31343e;
  border-radius: 20px 20px 0px 0px;
}

.tab-card-head h4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.tab-card-head h4 span {
  font-style: normal;
  font-family: "DDCHardware";
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #83bc9a;
}

.tab-card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  isolation: isolate;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding-top: 10px;
}

.tab-card-element {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 16px;
  gap: 8px;
}

/****************** Hand History Tab ******************/

.hand-history-head {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.hand-history-head-left {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.hand-history-head-left .hand-count {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #a5a5a5;
  width: 20px;
}

.hand-history-profile-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.table-last-winner .hand-history-profile-wrapper {
  position: absolute;
  flex-direction: row;
  padding: 0px;
  gap: 8px;
  display: flex;
  width: 86px;
  height: 86px;
  justify-content: center;
  align-items: center;
  top: -43px;
  border-radius: 100%;
  box-shadow: 0px 0px 30px 0px rgba(186, 143, 42, 0.5);
}

.hand-history-profile-wrapper img {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border: 1px solid #373940;
  border-radius: 100px;
  -o-object-fit: cover;
  object-fit: cover;
}

.table-last-winner .hand-history-profile-wrapper img {
  width: 86px;
  min-width: 86px;
  height: 86px;
  border: 3px solid #eab612;
}

.hand-winner-name {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
}

.table-last-winner .hand-winner-name {
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
  z-index: 1;
}

.hand-winner-name p {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  color: #fff;
}

.table-last-winner .hand-winner-name p {
  color: #ddab34;
  font-family: "DDCHardware";
  font-size: 16px;
  line-height: 20px;
}

.table-last-winner .hand-winner-name p:last-child {
  font-family: inherit;
  color: #ddab34;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  text-transform: uppercase;
}

.hand-winner-name p span {
  color: #909090;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.hand-winner-name .hand-name {
  font-weight: 500;
  color: #c7ad7a;
}

.hand-history-head-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.hand-pot-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0px;
  gap: 4px;
}

.hand-pot-details p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  color: #a5a5a5;
}

.hand-pot-details span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.hand-history-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px;
  gap: 4px;
}

.hand-history-card-wrapper.highlighted-winner-cards {
  background: #c7ad7a;
  border-radius: 4px;
  padding: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.hand-history-card-wrapper img {
  width: 29px;
  height: 44px;
}

/************************** Rank Tab *********************/

.tab-card-element .element-title {
  font-family: "DDCHardware";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #a0aabb;
  margin: 0;
}

.rank-element-head .hand-winner-name p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.rank-chips-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 4px;
}

.rank-chips-details p {
  font-family: "DDCHardware";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin: 0;
}

/********************** Viewer Tab ********************/

.viewer-profile-status {
  position: relative;
}

.viewer-profile-status::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  top: 0;
  left: 0;
  border-radius: 100%;
}

.viewer-profile-status.online::before {
  background-color: #83bc9a;
}

.viewer-profile-status.offline::before {
  background-color: #d93355;
}

.viewer-call-icon {
  cursor: pointer;
}

/************************** Chat UI ****************************/

.tab-card-bottom {
  padding: 12px 16px;
  background: #31343e;
  border-radius: 0 0 20px 20px;
  width: 100%;
}

.Chat-input-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.Chat-input-wrapper .btn {
  width: 40px;
  height: 40px;
  padding: 8px;
}

.Chat-input-wrapper .btn:disabled,
.Chat-input-wrapper .btn.disabled {
  color: #fff;
  background: #c7ad7a;
  border-color: #c7ad7a;
}

.Chat-input-wrapper input.form-control {
  background: #454851;
  border-radius: 6px;
  border-color: #454851;
  padding: 12px 16px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  caret-color: #c7ad7a;
  padding-right: 64px;
}

.Chat-input-wrapper input.form-control:focus {
  outline: 0;
  box-shadow: none;
}

.Chat-input-wrapper input.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #a5a5a5;
}

.Chat-input-wrapper input.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a5a5a5;
}

.Chat-input-wrapper input.form-control::-moz-placeholder {
  color: #a5a5a5;
}

.Chat-input-wrapper input.form-control::-ms-input-placeholder {
  color: #a5a5a5;
}

.Chat-input-wrapper input.form-control::placeholder {
  color: #a5a5a5;
}

.cam-emoji-wrapper {
  position: absolute;
  right: 54px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.emoji-input-btn {
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.3s;
}

.emoji-input-btn:hover {
  opacity: 1;
}

.chat-content-wrapper {
  position: relative;
  width: 100%;
  height: calc(100% - 114px);
}

.tab-chat-element {
  position: relative;
  flex-direction: row;
  /*align-items: baseline;*/
  justify-content: flex-start;
}

.chat-text-wrapper {
  width: calc(100% - 29px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
}

.chat-text-wrapper p.chat-message {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  margin: 0;
  word-break: break-word;
}

.chat-detail-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
}

.chat-avatar {
  width: 32px;
  height: 32px;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid #373940;
  border-radius: 100px;
  margin-right: -4px;
  cursor: pointer;
}

.chat-detail-wrapper p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #c7ad7a;
  margin: 0;
}

.chat-detail-wrapper p.chat-user-name {
  transition: all 0.3s;
  cursor: pointer;
}

.chat-detail-wrapper p.chat-user-name:hover {
  text-decoration: underline;
}

.chat-detail-wrapper span {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #909090;
}

.chat-detail-wrapper span::before {
  position: absolute;
  content: "";
  background: #909090;
  top: 50%;
  left: -6.5px;
  width: 2px;
  height: 2px;
  border-radius: 100%;
  margin-top: 0px;
}

.tab-chat-element .chat-time {
  width: 29px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #909090;
  text-align: right;
}

.chat-content-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 8px;
  background: linear-gradient(
    180deg,
    rgba(49, 52, 62, 1) 0%,
    rgba(49, 52, 62, 0.2) 100%
  );
  z-index: 1;
}

.chat-react-btn.btn,
.chat-react-btn.btn:hover,
.chat-react-btn.btn:focus,
.chat-react-btn.btn:focus-visible,
:not(.chat-react-btn.btn-check) + .chat-react-btn.btn:active,
.chat-react-btn.btn:active {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: 0;
  box-shadow: none;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-react-btn.btn svg path {
  transition: all 0.3s;
}

.chat-react-btn.btn:hover svg path {
  fill: #fff;
}

.chat-react-btn.btn.active svg path {
  fill: #fff;
}

.chat-right-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;
  height: 100%;
}

.main-player-game-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.hand-reacted-emoji-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.hand-chat-elem-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.cam-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  box-shadow: 0px 10px 60px 0px #1b1d23;
  overflow: hidden;
}

.cam-input-wrapper span {
  display: flex;
  height: 40px;
  padding: 12px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  font-size: 14px;
  font-family: Gilroy;
  line-height: 16px;
  color: #fff;
  background: #454851;
  transition: all 0.3s;
  cursor: pointer;
}

.cam-input-wrapper span:hover {
  background: #4c505e;
}

.cam-input-modal .modal-body {
  display: flex;
  padding: 16px 20px 20px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.cam-shutter-btn-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cam-shutter-btn-wrapper .shutter-btn,
.cam-shutter-btn-wrapper .cam-select-btn {
  cursor: pointer;
}

.selfie-cam img,
.selfie-cam video {
  min-width: 520px;
  width: 520px;
  height: 303px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #4c505e;
}

.cam-setting-btn-wrapper .drop-wrapper {
  max-width: 245px;
  width: 100%;
  right: 0;
  left: auto;
  bottom: 50px;
  top: auto;
}

.preview-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  cursor: pointer;
}

.selfie-cam {
  width: 100%;
  height: 303px;
}

.cam-shutter-btn-wrapper .tab-card-bottom {
  padding: unset;
  background: unset;
  border-radius: unset;
}

.intro-drag-video span img {
  min-width: unset;
  width: unset;
  height: unset;
  -o-object-fit: unset;
  object-fit: unset;
  border-radius: unset;
  border: unset;
}

.selfie-cam .intro-drag-video {
  width: 100%;
}

.upload-chat-image-modal .selfie-cam {
  height: unset;
  width: 100%;
}

.upload-chat-image-modal .add-company-logo {
  margin-bottom: unset;
}

.upload-chat-image-modal .add-company-logo h6 {
  margin-bottom: 20px;
}

.chat-media-file {
  width: 150px;
  height: 100px;
  border-radius: 6px;
  -o-object-fit: cover;
  object-fit: cover;
}

/************************************************************************************/
/********************************* Multi Table ***************************************/
/************************************************************************************/

.multi-table-slider-wrapper {
  background: transparent !important;
  /*min-height: 299px !important;*/
}

.player-game-view-top-right.multi-table {
  min-width: 664px;
  height: 510px;
}

.player-game-view-top-left.multi-table {
  width: 916px;
}

.player-game-view-container.multi-table {
  width: 916px;
  height: 510px;
}

.player-game-view-container.multi-table .player-game-grid {
  transform: scale(73%) translate(0%, -17.7%);
}

.tab-card-element.hand-chat-elem {
  padding: 8px 16px;
}

.hand-chat-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  padding: 12px;
  background: #454851;
  border-radius: 8px;
}

.hand-chat-wrapper.table-last-winner {
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 56px 12px 12px 12px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: linear-gradient(135deg, #eab20b 0%, #f9ce1f 100%, #f9ce1f 100%);
  box-shadow: 0px 0px 30px 0px rgba(186, 143, 42, 0.5);
  margin-top: 43px;
}

.hand-chat-wrapper.table-last-winner::before {
  content: "";
  position: absolute;
  background-image: url(../../../assets/images/confitti.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 80%;
  top: 0;
  left: 0;
  right: 0;
}

.player-game-view-top-right.multi-table .tab-card-element,
.player-game-view-top-right.multi-table .hand-chat-wrapper {
  flex-direction: row;
  align-items: center;
}

.player-game-view-top-right.multi-table
  .tab-card-element
  .hand-history-card-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/************************* Game New Sidebar collapse ***********************/

.player-game-view-top-left.hide {
  width: 1515px;
}

.sidebar-toggle {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 5.8156px 34.8936px #1b1d23;
  border-radius: 8px;
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.sidebar-toggle:hover {
  background: #a0aabb;
}

.sidebar-toggle:focus,
.sidebar-toggle:focus-visible {
  background: #fff;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.sidebar-toggle svg {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.sidebar-toggle.hide svg {
  transform: rotate(0deg);
}

.game-sidebar-main.hide {
  min-width: unset;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.game-sidebar-main.hide .game-stats-wrapper {
  flex-direction: column;
}

.game-sidebar-main.hide .side-setting-wrapper,
.game-sidebar-main.hide .table-drop-wrapper,
.game-sidebar-main.hide .game-stats-wrapper {
  width: 105px;
}

.game-sidebar-main.hide .side-setting-wrapper .setting-btn.btn {
  transition: unset;
}

.game-sidebar-main.hide .side-setting-wrapper .setting-btn-group:nth-child(2),
.game-sidebar-main.hide .side-setting-wrapper .setting-btn-group:nth-child(3),
.game-sidebar-main.hide .side-setting-wrapper .setting-btn-group:nth-child(4),
.game-sidebar-main.hide .chat-wrapper {
  display: none;
}

.player-game-view-top-right.hide.multi-table {
  min-width: unset;
  /*height: unset;*/
}

.game-sidebar-main.hide .stat-btn.btn h3 {
  display: none;
}

.player-game-view-top-left.hide.multi-table
  .player-game-view-container.multi-table {
  width: 100%;
}

/************************* Game New Sidebar collapse ***********************/

.player-action-view.timer {
  background: #c7ad7a;
  border-radius: 2px;
  border-color: #c7ad7a;
  font-family: "DDCHardware";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.player-length-3 .player-action-view.timer,
.player-length-4 .player-action-view.timer {
  font-size: 26px;
  line-height: 32px;
}

.add-time {
  width: 51px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background: #57b661;
  border-radius: 0px 0px 12px 0px;
  z-index: 99;
}
.add-time span {
  position: relative;
  font-family: "DDCHardware";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
}

.add-time span img {
  position: absolute;
  top: 18px;
  right: 0;
  width: 10px;
  height: 10px;
}

.player-action-view.timer.green {
  background: #57b661;
  border-color: #57b661;
}

/*************************** Show Card BTN *********************/

.show-bg-btn.btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  gap: 8px;
  background: #c7ad7a;
  border-color: #c7ad7a;
  border-radius: 6px;
  color: #ffffff;
}

.show-bg-btn.btn:hover {
  background: #af935d;
  border-color: #af935d;
}

.show-bg-btn.btn:focus,
.show-bg-btn.btn:focus-visible,
.show-bg-btn.btn-check:checked + .show-bg-btn.btn,
.show-bg-btn.btn.active,
.show-bg-btn.btn.show,
.show-bg-btn.btn:first-child:active,
:not(.show-bg-btn.btn-check) + .show-bg-btn.btn:active {
  background: #c7ad7a;
  border-color: #c7ad7a;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.show-bg-btn.btn:disabled,
.show-bg-btn.btn.disabled {
  background: #c7ad7a;
  border-color: #c7ad7a;
  color: #31343e;
}

.show-outline-btn.btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  gap: 8px;
  background: #373940;
  border-color: #c7ad7a;
  border-radius: 6px;
  color: #c7ad7a;
}

.show-bg-btn.btn span,
.show-outline-btn.btn span {
  font-family: "DDCHardware";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 48px;
}

.show-outline-btn.btn:hover {
  background: #373940;
  border-color: #af935d;
  color: #af935d;
}

.show-outline-btn.btn:focus,
.show-outline-btn.btn:focus-visible,
.show-outline-btn.btn-check:checked + .show-outline-btn.btn,
.show-outline-btn.btn.active,
.show-outline-btn.btn.show,
.show-outline-btn.btn:first-child:active,
:not(.show-outline-btn.btn-check) + .show-outline-btn.btn:active {
  background: #373940;
  border-color: #373940;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
  color: #c7ad7a;
}

.show-outline-btn.btn:disabled,
.show-outline-btn.btn.disabled {
  background: #373940;
  border-color: #c7ad7a;
  color: #ffffff;
}

.player-game-action-grid.show-card {
  justify-content: center;
}

.show-btn-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 24px;
  border-radius: 2px;
  overflow: hidden;
}

.show-btn-card img {
  width: 100%;
  height: 50px;
  -o-object-fit: contain;
  object-fit: contain;
}

/*************************** Show Card BTN *********************/

.player-length-3 .company-logo-section img,
.player-length-4 .company-logo-section img {
  width: 26px;
  height: 26px;
}

.player-box-iframe.multi-table-avatar {
  display: none;
}

.dealer-name-wrapper.multi-table-count {
  display: none;
}

.rail-slider-wrapper.multi-table-slider-wrapper {
  opacity: 0;
  visibility: hidden;
}
.rail-slider-wrapper.multi-table-slider-wrapper.multi-table-show {
  height: 325.3px;
  opacity: 1;
  visibility: visible;
  overflow: unset;
}
.chat-input-emoji {
  position: absolute;
  right: 40px;
  top: -60px;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s;
}

.chat-input-cam {
  width: 116px;
  top: unset;
  bottom: 35px;
  right: 45%;
}

.chat-input-emoji.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.chat-reaction-emoji {
  position: absolute;
  right: 16px;
  bottom: 20px;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s;
}

.chat-reaction-emoji.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.reacted-emoji-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  cursor: pointer;
}

.reacted-emoji {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  gap: 4px;
  background: #454851;
  border-radius: 6px;
}

.reacted-emoji p {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #fff;
  margin: 0;
}

.reacted-emoji span {
  font-size: 18px !important;
}

.reaction-info-wrapper {
  position: absolute;
  width: 120px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  bottom: 30px;
  left: -6px;
  background: #23262e;
  border-radius: 6px;
  padding: 8px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  z-index: 9;
}

.reacted-emoji:hover .reaction-info-wrapper {
  display: flex;
}

.reacted-emoji .reacting-username {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.reacted-emoji .reacting-username p {
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  word-break: break-word;
}

.reacted-emoji .reacting-username p::after {
  position: absolute;
  content: ",";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  right: -4px;
  bottom: 0;
}

.reacted-emoji .reacting-username p:last-child::after {
  display: none;
}

.reacted-emoji p.reaction-info-text {
  color: #909090;
  display: flex;
  align-items: center;
}

.multi-table-dealer-view {
  display: none;
}

.add-player-notification {
  position: fixed;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 8px;
  top: 20px;
  right: 0;
  border-radius: 12px;
  background: #83bc9a;
  box-shadow: 0px 10px 60px 0px #1b1d23;
  z-index: 99;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.add-player-notification p {
  margin: 0;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.add-player-notification span {
  cursor: pointer;
}

.cam-shutter-btn-wrapper .Chat-input-wrapper input.form-control {
  padding-right: 36px;
}

.player-pot-count ul li:nth-child(3) {
  border: none;
}
.player-pot-count ul li:nth-child(4),
.player-pot-count ul li:nth-child(5),
.player-pot-count ul li:nth-child(6),
.player-pot-count ul li:nth-child(7),
.player-pot-count ul li:nth-child(8),
.player-pot-count ul li:nth-child(9) {
  display: none;
}

/************************** One to One notification *********************************/

.one-to-one-call-notification-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  top: 20px;
  right: 0;
  z-index: 999;
}

.one-to-one-call-notification {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: #d93355;
  box-shadow: 0px 10px 60px 0px #1b1d23;
  padding: 16px;
  justify-content: space-between;
  gap: 20px;
  overflow: hidden;
  transition: all 0.3s;
}

.one-to-one-call-notification:hover {
  width: 360px;
  background: #4c505e;
}

.one-to-one-notification-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.rail-end-call.btn {
  display: none !important;
}

.one-to-one-call-notification:hover .rail-end-call.btn {
  display: flex !important;
}

.one-to-one-player-name {
  display: none;
  flex-direction: column;
}

.one-to-one-call-notification:hover .one-to-one-player-name {
  display: flex;
}

.one-to-one-player-name p {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.one-to-one-player-name span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #a0aabb;
}

.one-to-one-notification-wrapper p {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
}

.one-to-one-player-name.joined {
  flex-direction: row;
  -moz-column-gap: 4px;
  column-gap: 4px;
}

.close-notification-btn {
  cursor: pointer;
}

.fade.poker-popup.player-history-modal,
.fade.bar-room-setting-popup {
  left: 50%;
}

.player-box.player-position3 .profile-viewer,
.player-box:last-child .profile-viewer,
.player-box.player-position4 .profile-viewer {
  top: unset;
  bottom: 0;
}

/****************************************************************************************/
/******************************* Hand Info View ***********************************/
/****************************************************************************************/

.hand-info-view {
  position: fixed;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  left: 0px;
  z-index: 99;
  top: 20px;
  transition: all 0.3s;
}

.hand-info-main {
  display: inline-flex;
  align-items: flex-start;
}

.hand-info-wrapper {
  display: flex;
  width: 0;
  height: 898px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 0px 20px 20px 0px;
  background: #31343e;
  box-shadow: 0px 10px 60px 0px #1b1d23;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}

.hand-info-wrapper.show {
  width: 374px;
  opacity: 1;
  visibility: visible;
}

.hand-info-head {
  display: flex;
  height: 58px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
}

.hand-info-head h5 {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
  margin: 0;
}

.hand-info-card-wrapper {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.hand-info-card {
  display: flex;
  align-items: center;
  gap: 8px;
}

.hand-info-card-text {
  display: flex;
  width: 135px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.hand-info-card-text h6 {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  align-self: stretch;
}

.hand-info-card-text p {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin: 0;
  align-self: stretch;
}

.hand-info-card-box {
  display: flex;
  align-items: flex-start;
  gap: 3.438px;
}

.hand-info-card-box img {
  width: 37.249px;
  height: 55.014px;
  -o-object-fit: contain;
  object-fit: contain;
}

.hand-info-btn {
  display: flex;
  width: 48px;
  height: 40px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 12px 12px 0px;
  background: #c7ad7a;
  box-shadow: 0px 10px 60px 0px #1b1d23;
  margin-top: 30px;
  cursor: pointer;
}

.hand-info-btn svg {
  width: 28px;
  height: 28px;
  transition: all 0.3s;
}

.hand-info-btn.close svg {
  transform: rotate(90deg);
}

/******************************* Hand Info View End ***********************************/

/**********************************************************************************/
/*************************************** Media Query ***************************************/
/**********************************************************************************/

@media (min-height: 600px) and (max-height: 600px) and (min-width: 1024px) and (max-width: 1024px) {
  /*.player-length-4 .player-box-action-grid {
    padding: 2px 10px 5px;
  }
  .player-length-4 .player-action-view,
  .player-length-4 .player-action-view-check,
  .player-length-4 .player-action-view-fold,
  .player-length-4 .player-action-view-show {
    border-width: 2px;
  }
  .player-length-4 .player-action-view-check,
  .player-length-4 .player-action-view-fold,
  .player-length-4 .player-action-view-show {
    padding: 7px 10px;
  }
  .player-length-4 .player-action-winner span {
    font-size: 16px;
    line-height: 16px;
  }
  .player-length-4 .player-box,
  .player-length-3 .player-box {
    border-width: 3px !important;
  }
  .player-game-right-view.player-length-4
    .winner-player
    .player-box-action-grid,
  .player-game-right-view.player-length-3
    .winner-player
    .player-box-action-grid {
    border-width: 3px !important;
  }
  .player-length-4 .winner-player .player-box-action-grid,
  .player-length-3 .winner-player .player-box-action-grid {
    border-width: 3px !important;
  }*/

  /**************************** Player 2 *********************************/

  /*.player-length-2 .player-action-view h4 {
    font-size: 18px;
  }

  .player-action-view-bet span {
    margin-left: 4px;
  }

  .player-length-2 .player-action-view,
  .player-length-2 .player-action-view-check,
  .player-length-2 .player-action-view-fold,
  .player-length-2 .player-action-view-show {
    padding: 6px 8px;
  }*/

  /***************************** player 3 **********************************/

  /*.player-length-3 .player-box-card span img {
    width: 46px;
    height: 61px;
    border: 1.8px solid rgb(255 255 255 / 35%);
  }

  .player-length-3 .card-animate-firstcard,
  .player-length-3 .card-animate-secondcard,
  .player-length-3 .card-animate-fold,
  .player-length-3 .card-animate-cardview {
    top: -57px;
    left: -8px;
    width: 146px;
    height: 146px;
  }*/

  /*********************** Player 4 ************************/

  /*.player-length-4 .player-box {
    height: calc(20vh - 0px);
  }

  .player-length-4 .player-box-card span img {
    width: 41px;
    border: 2px solid rgb(255 255 255 / 35%);
    height: 55px;
  }

  .player-length-4 .card-animate-firstcard,
  .player-length-4 .card-animate-secondcard,
  .player-length-4 .card-animate-fold,
  .player-length-4 .card-animate-cardview {
    top: -50px;
    left: -7px;
    width: 130px;
    height: 130px;
  }

  .player-length-4 .player-action-view {
    padding: 3px 10px;
  }

  .player-length-4 .player-action-empty-field {
    min-height: 42px;
	border-width: 2px;
  }*/
}

/*@media screen and (max-width: 1023px) {
  .game-view .App {
    display: none !important;
  }

  .game-view:before {
    content: "";
    background: url(../../../assets/images/playerview/imac.png);
    width: 200px;
    height: 156px;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999999;
  }
  .game-view #root:before {
    content: "";
    background: #000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999999;
    display: block;
  }

  .game-view #root:after {
    content: "For a better experience please run the website to Desktop mode!";
    color: #fff;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    z-index: 99999999;
    width: 100%;
    text-align: center;
    font-size: 18px;
    max-width: 400px;
    font-family: "DDCHardware" !important;
  }
}

@media screen and (max-width: 767px) {
  .game-view:before {
    width: 130px;
    height: 101px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .game-view #root:after {
    max-width: 300px;
  }
}*/

/* @media (min-height: 600px) and (max-height: 600px) and (min-width: 1024px) and (max-width: 1024px) { 
.player-length-1 .player-box-iframe img, .player-length-1 .player-box-iframe video {
    height: 360px;
}
.player-length-2 .player-box-iframe img, .player-length-2 .player-box-iframe video {
    height: 135px;
}

.player-length-3 .player-box-iframe img, .player-length-3 .player-box-iframe video {
    height: 140px;
}

.player-length-4 .player-box-iframe img, .player-length-4 .player-box-iframe video {
    height: 99px;
}

} */

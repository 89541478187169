
/************************* Leave Popup *****************************/

.bar-room-leave-popup .modal-dialog {
  max-width: 394px;
}

.bar-room-leave-popup .modal-dialog .modal-content {
  background: #31343e;
  box-shadow: 10px 10px 60px #1b1d23;
  border-radius: 20px;
  padding: 36px;
}

.bar-room-leave p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 20px;
}

.bar-room-leave-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
}

.bar-room-leave-btn .bg-btn.btn {
  background: #c7ad7a;
  border-radius: 6px;
  border-color: #c7ad7a;
  max-width: 100%;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: "DDCHardware" !important;
  height: 50px;
  box-shadow: none;
}

.bar-room-leave-btn .bg-btn.btn:hover,
.bar-room-leave-btn .bg-btn.btn-check:checked + .bar-room-leave-btn .bg-btn.btn,
.bar-room-leave-btn .bg-btn.btn.active,
.bar-room-leave-btn .bg-btn.btn.show,
.bar-room-leave-btn .bg-btn.btn:first-child:active,
:not(.bar-room-leave-btn .bg-btn.btn-check)
  + .bar-room-leave-btn
  .bg-btn.btn:active {
  background: #af935d;
  border-color: #af935d;
  color: #fff;
}

.bar-room-leave-btn .bg-btn.btn:focus,
.bar-room-leave-btn .bg-btn.btn:focus-visible {
  background: #c7ad7a;
  border-color: #c7ad7a;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.bar-room-leave-btn .outline-btn.btn {
  background: transparent;
  color: #c7ad7a;
  margin: 0;
}

.bar-room-leave-btn .outline-btn.btn:hover,
.bar-room-leave-btn
  .outline-btn.btn-check:checked
  + .bar-room-leave-btn
  .outline-btn.btn,
.bar-room-leave-btn .outline-btn.btn.active,
.bar-room-leave-btn .outline-btn.btn.show,
.bar-room-leave-btn .outline-btn.btn:first-child:active,
:not(.bar-room-leave-btn .outline-btn.btn-check)
  + .bar-room-leave-btn
  .outline-btn.btn:active {
  background: transparent;
  border-color: #af935d;
  color: #af935d;
}

.bar-room-leave-btn .outline-btn.btn:focus,
.bar-room-leave-btn .outline-btn.btn:focus-visible {
  background: transparent;
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}



.volume-range-main {
  width: 100%;
  margin: 6% auto;
  position: relative;
  height: 8px;
  background: #454851;
  border-radius: 20px;
}


.volume-range-main input:focus-visible {
  outline: 0;
}

.volume-range-main #slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  padding-bottom: 17px;
  border-radius: 20px;
  background: transparent;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Design slider button */
.volume-range-main #slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  z-index: 3;
}

.volume-range-main #slider::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 0;
  visibility: hidden;
}

.volume-range-main #selector {
  height: 24px;
  width: 24px;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-100%, 50%);
  z-index: 1;
}
.volume-range-main .volume-range-selectBtn {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  background: #fff;
}
.volume-range-main #progressBar {
  width: 50%;
  height: 8px;
  background: #c7ad7a;
  border-radius: 20px;
  position: absolute;
  bottom: 0px;
  left: 0;
}



.create-game-btn {
  max-width: 220px;
  width: 100%;
}
.create-game-btn .btn {
  width: 100%;
  height: 50px;
  background: #c7ad7a;
  border-radius: 6px;
  border: none;
  font-family: "DDCHardware" !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.create-game-btn .btn:hover,
.create-game-btn .btn:focus,
.create-game-btn :not(.btn-check) + .btn:active,
.create-game-btn .btn:focus-visible {
  background: #af935d !important;
  border-color: none;
  outline: 0;
}
.create-game-btn .btn:disabled {
  background: #454851;
  color: #31343e;
}

.game-filter-grid {
  padding-top: 30px;
}
.game-filter-grid form {
  display: flex;
  gap: 16px;
}
.game-filter-grid .form-group {
  max-width: 314px;
  width: 100%;
}
.game-filter-search {
  position: relative;
}
.game-filter-search .form-control,
.game-filter-search .form-control:hover,
.game-filter-search .form-control:focus {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  height: 48px;
  padding: 8px 12px;
  padding-left: 46px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a5a5a5;
}
.game-filter-search .form-control:focus {
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 50%);
}
.game-filter-search img {
  position: absolute;
  top: 10px;
  left: 14px;
}
.game-filter-date {
  position: relative;
}
.game-filter-date .react-datepicker__input-container input {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  height: 48px;
  padding: 8px 12px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a5a5a5;
}
.game-filter-date img {
  /*position: absolute;
	top: 13px;
	right: 13px;*/
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 13px;
}
.game-filter-search .form-control::-webkit-input-placeholder,
.game-filter-date
  .react-datepicker__input-container
  input::-webkit-input-placeholder {
  color: #a5a5a5;
}
.game-filter-search .form-control::-moz-placeholder,
.game-filter-date .react-datepicker__input-container input::-moz-placeholder {
  color: #a5a5a5;
}
.game-filter-search .form-control:-ms-input-placeholder,
.game-filter-date
  .react-datepicker__input-container
  input:-ms-input-placeholder {
  color: #a5a5a5;
}
.game-filter-search .form-control::-ms-input-placeholder,
.game-filter-date
  .react-datepicker__input-container
  input::-ms-input-placeholder {
  color: #a5a5a5;
}
.game-filter-search .form-control::placeholder,
.game-filter-date .react-datepicker__input-container input::placeholder {
  color: #a5a5a5;
}

.game-filter-grid {
  display: none;
}

.create-game-btn {
  display: none;
}

.dealer-home-header .game-filter-grid {
  display: block;
}

.dealer-home-header .create-game-btn {
  display: block;
}
img.full-logo {
  display: none;
}
.dealer-home-header img.full-logo {
  display: block;
}
.dealer-home-header img.icon-logo {
  display: none;
}

.user-main-game-header .logo {
  display: none;
}

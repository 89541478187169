.game-creation-modal .modal-content {
  background: #31343e;
  border-radius: 20px;
}

.game-creation-modal .modal-dialog {
  max-width: 1328px;
}

.game-creation-modal .modal-header {
  border: none;
}

.game-creation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}

.game-creation-form {
  width: 100%;
}

.game-creation-content {
  background: #31343e;
  border-radius: 20px;
  padding: 50px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0aabb;
}

.game-creation-content form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.game-creation-content form:after {
  content: "";
  width: 2px;
  height: 340px;
  background: #454851;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.game-creation-left {
  width: calc(50% - 50px);
}

.game-creation-right {
  width: calc(50% - 50px);
}

.game-creation-content h3 {
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 40px;
}

.game-creation-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.game-creation-content .form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0aabb;
  margin-bottom: 9px;
}

.game-creation-content .form-control,
.game-creation-content .form-control:focus {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  padding: 5px 12px;
}

.game-creation-content .form-control::-webkit-input-placeholder {
  color: #a5a5a5;
}

.game-creation-content .form-control::-moz-placeholder {
  color: #a5a5a5;
}

.game-creation-content .form-control:-ms-input-placeholder {
  color: #a5a5a5;
}

.game-creation-content .form-control::-ms-input-placeholder {
  color: #a5a5a5;
}

.game-creation-content .form-control::placeholder {
  color: #a5a5a5;
}

.game-creation-content .form-group {
  margin-bottom: 20px;
  position: relative;
}

.game-creation-third-width {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.game-creation-third-width .form-group {
  width: 32%;
}

.game-creation-half-width {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.game-creation-half-width .form-group {
  width: 49%;
}

.game-creation-half-width .form-check-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #a0aabb;
  cursor: pointer;
}

.game-creation-half-width .form-check {
  margin: 12px 0px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  position: relative;
}

.form-check-input[type="radio"] {
  background: #454851;
  border: 1px solid #a0aabb;
  width: 20px;
  height: 20px;
  margin: 0 !important;
  position: absolute;
  left: 0;
  top: 2px;
}

/******************* Date Picker ******************/

.react-datepicker {
  background-color: #454851;
  color: #fff;
  border: 1px solid #454851;
  border-radius: 8px;
  font-family: "Gilroy";
}

.react-datepicker__input-container input {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  color: #a5a5a5;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  padding: 5px 12px;
  width: 100%;
}

.react-datepicker__input-container input::-webkit-input-placeholder {
  color: #a5a5a5;
}

.react-datepicker__input-container input::-moz-placeholder {
  color: #a5a5a5;
}

.react-datepicker__input-container input:-ms-input-placeholder {
  color: #a5a5a5;
}

.react-datepicker__input-container input::-ms-input-placeholder {
  color: #a5a5a5;
}

.react-datepicker__input-container input::placeholder {
  color: #a5a5a5;
}

.react-datepicker__input-container input:focus {
  outline: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover {
  background: #c7ad7a !important;
  font-weight: 400;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #fff;
}
.react-datepicker__header {
  background-color: #454851;
  border-bottom: 1px solid #454851;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #fff;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  border-top-color: #454851;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  border-top-color: #454851;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #454851;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #454851;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background: transparent;
}

.react-datepicker__day-names {
  margin-top: 20px;
  margin-bottom: 0;
}
.react-datepicker__day-name {
  color: #a5a5a5;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.react-datepicker__current-month {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

/******************* React Select time Picker ***************/

.game-creation-content .css-1s2u09g-control,
.game-creation-content .css-1pahdxg-control {
  background: #454851 !important;
  border-color: #a0aabb !important;
  min-height: 40px !important;
  box-shadow: none !important;
}

.game-creation-content .css-14el2xx-placeholder {
  color: #a5a5a5;
}

.game-creation-content .css-6j8wv5-Input,
.game-creation-content .css-qc6sy-singleValue {
  color: #fff;
}

.game-creation-content .css-1rhbuit-multiValue {
  background: #373940;
  border-radius: 6px;
}

.game-creation-content .css-12jo7m5 {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

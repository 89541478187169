.joinErrorModal h4 {
  color: #fff;
  width: 100%;
  text-align: center;
  font-weight: 410;
  font-size: 20px;
  line-height: 26px;
}
.joinErrorModal p {
  color: #fff;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.joinErrorModal .joinErrorModal-btn {
  padding: 0px 36px;
  background: #c7ad7a;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
  height: 50px;
  border: none;
}
.joinErrorModal .joinErrorModal-btn:hover,
.joinErrorModal .joinErrorModal-btn:focus,
.joinErrorModal .joinErrorModal-btn:active {
  background: #af935d;
}
.joinErrorModal .joinErrorModal-borderBtn {
  margin-top: 12px;
  width: 100%;
  height: 50px;
  border: 1px solid #c7ad7a;
  border-radius: 6px;
  background: transparent;
  color: #c7ad7a;
}
.joinErrorModal .joinErrorModal-borderBtn:hover,
.joinErrorModal .joinErrorModal-borderBtn:focus,
.joinErrorModal .joinErrorModal-borderBtn:active {
  background: transparent;
  border: 1px solid #af935d;
  color: #af935d;
}

.waiting-room-content-container .container {
  max-width: 1630px !important;
  width: 100%;
  position: relative;
}
.waiting-room-content {
  position: absolute;
  top: 0; /* AS REQUIRED ACCORDING TO CONTENT */
  left: 50%;
  transition: all 0.5s;
  /*padding-bottom: 38px;*/
  width: 1630px;
  height: 922px;
  transform: translate(-50%, 4%);
}
.waiting-room-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.waiting-logo {
  min-width: 314px;
  cursor: pointer;
}

.waiting-text {
  position: relative;
  background: url(../../../assets/images/waitingroom/bg.svg) no-repeat;
  height: 80px;
  width: 720px;
}
.waiting-text h4 {
  font-weight: 410;
  font-size: 30px;
  line-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 26px 0 0 0;
  margin: 0;
}
.waiting-btn {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 20px;
}
.waiting-con-btn {
  display: flex;
  align-items: center;
  gap: 16px;
}
.waiting-mic {
  background: #c7ad7a;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
}
.waiting-mic:hover {
  background: #af935d;
}
.waiting-camera {
  background: #c7ad7a;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
}
.waiting-camera:hover {
  background: #af935d;
}
.waiting-leave-btn.btn {
  background: #d93355;
  border-radius: 6px;
  width: 170px;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  font-family: "DDCHardware" !important;
}
.waiting-leave-btn.btn:hover,
.waiting-leave-btn.btn-check:checked + .waiting-leave-btn.btn,
.waiting-leave-btn.btn.active,
.waiting-leave-btn.btn.show,
.waiting-leave-btn.btn:first-child:active,
:not(.waiting-leave-btn.btn-check) + .waiting-leave-btn.btn:active {
  background: #d6002b;
  border: none;
  outline: 0;
}

.waiting-leave-btn.btn:focus,
.waiting-leave-btn.btn:focus:not(:focus-visible),
.waiting-leave-btn.btn:focus-visible {
  background: #d93355;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}
.wr-camera-grid {
  position: relative;
  padding-top: 32px;
}
.wr-camera-box {
  width: 388px;
  height: 253px;
  background: #373940;
  border-radius: 8px;
  position: relative;
}
.wr-camera-name {
  position: absolute;
  bottom: 16px;
  left: 12px;
  background: rgba(35, 38, 46, 0.7);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: 4px;
  padding: 6px 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.wr-camera-name p {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  margin: 0;
}
.wr-main-camera {
  width: 100%;
  height: 100%;
  border: 5px solid #31343e;
  border-radius: 6px;
}
.wr-camera-gridbox-center {
  position: absolute;
  width: 794px;
  height: 519px;
  left: 420px;
}
.wr-camera-box:first-child {
  position: absolute;
  left: 10px;
}
.wr-camera-box:nth-child(2) {
  position: absolute;
  top: 302px;
  left: 10px;
}
.wr-camera-box:nth-child(3) {
  position: absolute;
  top: 572px;
  left: 10px;
}
.wr-camera-box:nth-child(4) {
  position: absolute;
  top: 572px;
  left: 420px;
}

.wr-camera-box:nth-child(5) {
  position: absolute;
  top: 572px;
  left: 826px;
}
.wr-camera-box:nth-child(6) {
  position: absolute;
  right: 10px;
}
.wr-camera-box:nth-child(7) {
  position: absolute;
  top: 302px;
  right: 10px;
}
.wr-camera-box:nth-child(8) {
  position: absolute;
  top: 572px;
  right: 10px;
}

.wr-camera-box:nth-child(3) .profile-viewer,
.wr-camera-box:nth-child(4) .profile-viewer,
.wr-camera-box:nth-child(5) .profile-viewer,
.wr-camera-box:nth-child(8) .profile-viewer {
  transform: translateY(-44%);
}

.camera-active {
  border: 2px solid #00acee;
}

/*no person img or overlay */

.wr-camera-box .noplayerImg {
  position: absolute;
  border-radius: 8px;
  display: grid;
  place-content: center;
  z-index: 10;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.wr-camera-box video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  background-size: cover;
  border-radius: 8px !important;
}
.wr-camera-box .cameraoff {
  border: 3px solid #454851;
  width: 64px;
  height: 64px;
  border-radius: 50% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wr-camera-box video::-webkit-media-controls {
  display: none;
}
.waiting-camera-close {
  background-color: #fff;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
}
.waiting-mic-close {
  background-color: #fff;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
}
/* Could Use thise as well for Individual Controls */
video::-webkit-media-controls-play-button {
}

video::-webkit-media-controls-volume-slider {
}

video::-webkit-media-controls-mute-button {
}

video::-webkit-media-controls-timeline {
}

video::-webkit-media-text-track-container {
  display: none;
}

video::-webkit-media-text-track-background {
  display: none;
}

video::-webkit-media-text-track-display {
  display: none;
}

.guestImgContainer img,
.guestImgContainer video {
  width: 100%;
  height: 509px;
  -o-object-fit: cover;
  object-fit: cover;
}

.waiting-btn .setting-btn {
  height: 50px;
  border-radius: 6px;
}

.waiting-btn .drop-wrapper {
  top: 66px;
}
